import { useEffect } from "react";

import { detectPlatform, Platform } from "../utils/detectPlatform";

const DEFAULT_PAGE_TITLE = "";

type PageSetting = {
	pageId: number;
	pageTitle?: string;
};

export function usePageSettings(params: PageSetting) {
	useEffect(() => {
		const platform = detectPlatform();
		const pageTitle = params.pageTitle || DEFAULT_PAGE_TITLE;

		if (platform === Platform.ANDROID) {
			// @ts-ignore
			window.Android?.setPageSettings(JSON.stringify(params));
		} else if (platform === Platform.IOS) {
			const qParams = new URLSearchParams({
				pageId: String(params.pageId),
				pageTitle: String(pageTitle),
			});

			window.location.replace(`ios:setPageSettings/?${qParams.toString()}`);
		} else {
			//TODO Dev only
			const title = document.getElementById("page-title");

			if (title) {
				title.innerHTML = pageTitle;
			}
		}
	}, [params]);
}
