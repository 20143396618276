import { useEffect, useMemo } from "react";
import { Button } from "@alfalab/core-components/button";

import { useActions } from "@/lib/actions/ActionsContext";
import { useToggle } from "@/lib/hooks";
import { Selection } from "@/lib/types";

import { Stories } from "./stories/Stories";

import styles from "./Selections.module.css";

type SelectionsProps = {
	data: Selection[];
};

export const Selections: React.FC<SelectionsProps> = ({ data }) => {
	const { selections: citySelections, totalFiltersCount } = useActions();
	const [isShowAllSelections, toggleShowAllSelections] = useToggle(false);

	const selections = useMemo(() => {
		if (isShowAllSelections) {
			return data;
		} else {
			return totalFiltersCount > 0 ? [] : [...data].slice(0, 2);
		}
	}, [data, isShowAllSelections, totalFiltersCount]);

	useEffect(() => {
		if (totalFiltersCount > 0) {
			toggleShowAllSelections(false);
		}
	}, [totalFiltersCount]);

	const hasAppliedFilters = totalFiltersCount > 0;
	const hasSelectionsButton =
		totalFiltersCount > 0 || citySelections.length > 3;

	return (
		<div className={styles["container"]}>
			{selections.map((selection) => (
				<Stories key={selection.id} data={selection} />
			))}

			{hasSelectionsButton && (
				<div className={styles["show-more-button"]}>
					<Button
						block
						view="primary"
						onClick={() => toggleShowAllSelections()}
					>
						{hasAppliedFilters
							? `${isShowAllSelections ? "Скрыть" : "Показать"} подборки`
							: isShowAllSelections
							? "Скрыть подборки"
							: "Посмотреть другие подборки"}
					</Button>
				</div>
			)}
		</div>
	);
};
