import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@alfalab/core-components/button";
import { Typography } from "@alfalab/core-components/typography";
import { PureCell } from "@alfalab/core-components/pure-cell";
import { Space } from "@alfalab/core-components/space";
import { Divider } from "@alfalab/core-components/divider";
import { Input } from "@alfalab/core-components/input";
import { CheckmarkMediumMIcon } from "@alfalab/icons-glyph/CheckmarkMediumMIcon";
import MagnifierMIcon from "@alfalab/icons-glyph/MagnifierMIcon";

import { useActions } from "@/lib/actions/ActionsContext";
import { placesHistory } from "@/lib/placesHistory";
import { formatString } from "@/lib/utils/formatString";
import { usePageSettings } from "@/lib/hooks";
import { MAX_POPULAR_PLACES } from "@/lib/constants";
import { Venue } from "@/lib/types";

import styles from "./VenuesView.module.css";

function getHighlightedText(text: string, highlight: string) {
	const parts = text.split(new RegExp(`( |^|-|«)(${highlight})`, "gi"));

	return (
		<span>
			{" "}
			{parts.map((part, i) => (
				<span
					key={`${i}`}
					style={
						part.toLowerCase() === highlight.toLowerCase()
							? { fontWeight: "bold" }
							: {}
					}
				>
					{part}
				</span>
			))}{" "}
		</span>
	);
}

type PlaceItemProps = {
	name: string;
	address: string;
	hightlight?: string;
	selected?: boolean;
	onClick(): void;
};

const PlaceItem: React.FC<PlaceItemProps> = ({
	name,
	address,
	hightlight,
	selected = false,
	onClick,
}) => {
	return (
		<button type="button" className={styles["item"]} onClick={onClick}>
			<PureCell verticalPadding="default">
				<PureCell.Content>
					<PureCell.Main>
						<Typography.Text>
							{hightlight ? getHighlightedText(name, hightlight) : name}
						</Typography.Text>
					</PureCell.Main>

					<PureCell.Footer footerPadding="none">
						<Typography.Text view="primary-small" color="secondary">
							{address}
						</Typography.Text>
					</PureCell.Footer>
				</PureCell.Content>

				<PureCell.Addon verticalAlign="center">
					<CheckmarkMediumMIcon
						width={16}
						height={16}
						aria-hidden={!selected}
						style={{ visibility: selected ? "visible" : "hidden" }}
					/>
				</PureCell.Addon>
			</PureCell>
		</button>
	);
};

function getPlaceFilter(cityVenues: Venue[], args?: { query: string }) {
	const rx = args ? new RegExp(`( |^|-|«)${args.query}`, "gi") : null;

	return ({ venueId, venueName }: Venue) => {
		const queryMatched = rx ? rx.test(venueName) : true;
		const cityMatched = cityVenues.some((venue) => venue.venueId === venueId);

		return queryMatched && cityMatched;
	};
}

type FiltersVenuesViewProps = {
	pageId: number;
};

export const FiltersVenuesView: React.FC<FiltersVenuesViewProps> = ({
	pageId,
}) => {
	const navigate = useNavigate();
	const { city, filters, venues: cityVenues, setFilters } = useActions();
	const [query, setQuery] = useState("");
	const [places, setPlaces] = useState<Venue[]>(filters.venues);

	usePageSettings({
		pageId,
		pageTitle: "Место проведения",
	});

	const results = useMemo(() => {
		return cityVenues.filter(getPlaceFilter(cityVenues, { query }));
	}, [query]);

	const history = useMemo(() => {
		return placesHistory.getItems().filter(getPlaceFilter(cityVenues));
	}, []);

	const popular = useMemo(() => {
		return cityVenues.slice(0, MAX_POPULAR_PLACES - 1);
	}, []);

	const selectPlace = (place: Venue) => {
		setPlaces((state) => {
			const isExists = state.some(({ venueId }) => venueId === place.venueId);

			const placesToUpdate = isExists
				? state.filter(({ venueId }) => venueId !== place.venueId)
				: [...state, place];

			return placesToUpdate;
		});
	};

	const clear = () => {
		setPlaces([]);
	};

	const apply = () => {
		setFilters({
			...filters,
			venues: places,
		});

		placesHistory.addItems(places);

		navigate(`/city/${city.cityId}`);
	};

	return (
		<div className={styles["container"]}>
			<Input
				placeholder="Место проведения"
				size="s"
				block
				leftAddons={
					<MagnifierMIcon color="var(--color-dark-graphic-secondary)" />
				}
				clear={true}
				value={query}
				onChange={({ target }) => setQuery(target.value)}
				onClear={() => setQuery("")}
				className={styles["input"]}
			/>

			<div className={styles["content"]}>
				{query !== "" ? (
					<div>
						<Space size={0} fullWidth divider={<Divider />}>
							{results.length === 0 ? (
								<Typography.Text>Нет результатов</Typography.Text>
							) : (
								results.map((venue) => (
									<PlaceItem
										key={venue.venueId}
										name={formatString(venue.venueName)}
										address={formatString(venue.venueName)}
										hightlight={query}
										selected={places.some(
											({ venueId }) => venueId === venue.venueId,
										)}
										onClick={() => selectPlace(venue)}
									/>
								))
							)}
						</Space>
					</div>
				) : (
					<div>
						{history.length > 0 && (
							<div>
								<Typography.TitleMobile
									tag="h5"
									view="xsmall"
									weight="bold"
									style={{ padding: "16px 0" }}
								>
									История
								</Typography.TitleMobile>

								<Divider />

								<Space size={0} fullWidth divider={<Divider />}>
									{history.map((venue) => (
										<PlaceItem
											key={venue.venueId}
											name={venue.venueName}
											address={venue.venueName}
											selected={places.some(
												({ venueId }) => venueId === venue.venueId,
											)}
											onClick={() => selectPlace(venue)}
										/>
									))}
								</Space>
							</div>
						)}

						<Typography.TitleMobile
							tag="h5"
							view="xsmall"
							weight="bold"
							style={{ padding: "16px 0" }}
						>
							{city
								? `Популярные места в "${city.cityName}"`
								: "Популярные места"}
						</Typography.TitleMobile>

						<Divider />

						<Space size={0} fullWidth divider={<Divider />}>
							{popular.map((venue) => (
								<PlaceItem
									key={venue.venueId}
									name={formatString(venue.venueName)}
									address={formatString(venue.venueName)}
									selected={places.some(
										({ venueId }) => venueId === venue.venueId,
									)}
									onClick={() => selectPlace(venue)}
								/>
							))}
						</Space>
					</div>
				)}
			</div>

			<div className={styles["button-group"]}>
				{places.length > 0 && (
					<Button view="secondary" size="s" block onClick={clear}>
						Сбросить
					</Button>
				)}
				<Button view="primary" size="s" block onClick={apply}>
					Применить
				</Button>
			</div>
		</div>
	);
};
