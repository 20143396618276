import { useMemo, useState } from "react";
import { Input } from "@alfalab/core-components/input";
import { Gap } from "@alfalab/core-components/gap";
import { Space } from "@alfalab/core-components/space";
import { Divider } from "@alfalab/core-components/divider";
import { Typography } from "@alfalab/core-components/typography";
import { PureCell } from "@alfalab/core-components/pure-cell";
import { CheckmarkCircleMIcon } from "@alfalab/icons-glyph/CheckmarkCircleMIcon";

import { placesHistory } from "@/lib/placesHistory";
import { MAX_POPULAR_PLACES } from "@/lib/constants";
import { City, Venue } from "@/lib/types";

import styles from "./PlacesFilter.module.css";

function getHighlightedText(text: string, highlight: string) {
	const parts = text.split(new RegExp(`(^${highlight})`, "gi"));

	return (
		<span>
			{" "}
			{parts.map((part, i) => (
				<span
					key={`${i}`}
					style={
						part.toLowerCase() === highlight.toLowerCase()
							? { fontWeight: "bold" }
							: {}
					}
				>
					{part}
				</span>
			))}{" "}
		</span>
	);
}

type PlaceItemProps = {
	name: string;
	address: string;
	hightlight?: string;
	selected?: boolean;
	onClick(): void;
};

const PlaceItem: React.FC<PlaceItemProps> = ({
	name,
	address,
	hightlight,
	selected = false,
	onClick,
}) => {
	return (
		<button type="button" className={styles["item"]} onClick={onClick}>
			<PureCell verticalPadding="default">
				<PureCell.Content>
					<PureCell.Main>
						<Typography.Text color={selected ? "accent" : "primary"}>
							{hightlight ? getHighlightedText(name, hightlight) : name}
						</Typography.Text>
					</PureCell.Main>

					<PureCell.Addon verticalAlign="center">
						<CheckmarkCircleMIcon
							width={20}
							height={20}
							fill="var(--color-light-bg-accent)"
							aria-hidden={!selected}
							style={{ visibility: selected ? "visible" : "hidden" }}
						/>
					</PureCell.Addon>

					<PureCell.Footer footerPadding="none">
						<Typography.Text view="primary-small" color="secondary">
							{address}
						</Typography.Text>
					</PureCell.Footer>
				</PureCell.Content>
			</PureCell>
		</button>
	);
};

function getPlaceFilter(cityVenues: Venue[], args?: { query: string }) {
	const rx = args ? new RegExp(`^${args.query}`, "gi") : null;

	return ({ venueId, venueName }: Venue) => {
		const queryMatched = rx ? rx.test(venueName) : true;
		const cityMatched = cityVenues.some((venue) => venue.venueId === venueId);

		return queryMatched && cityMatched;
	};
}

type PlacesFilterProps = {
	city: City;
	cityVenues: Array<Venue>;
	selectedPlaces: Array<Venue>;
	onSelectPlace(place: Venue): void;
};

export const PlacesFilter: React.FC<PlacesFilterProps> = ({
	city,
	cityVenues,
	selectedPlaces,
	onSelectPlace,
}) => {
	const [query, setQuery] = useState("");

	const results = useMemo(() => {
		return cityVenues.filter(getPlaceFilter(cityVenues, { query }));
	}, [cityVenues, query]);

	const history = useMemo(() => {
		return placesHistory.getItems().filter(getPlaceFilter(cityVenues));
	}, [cityVenues]);

	const popular = useMemo(() => {
		return cityVenues.slice(0, MAX_POPULAR_PLACES - 1);
	}, [cityVenues]);

	return (
		<div>
			<Input
				block
				placeholder="Место проведения"
				size="s"
				clear={true}
				value={query}
				onChange={({ target }) => setQuery(target.value)}
				onClear={() => setQuery("")}
			/>

			{query !== "" ? (
				<div>
					<Gap size="s" />

					<Space size={0} fullWidth>
						{results.length === 0 ? (
							<Typography.Text>Нет результатов</Typography.Text>
						) : (
							results.map((venue) => (
								<PlaceItem
									key={venue.venueId}
									name={venue.venueName}
									address={venue.venueName}
									hightlight={query}
									selected={selectedPlaces.some(
										({ venueId }) => venueId === venue.venueId,
									)}
									onClick={() => onSelectPlace(venue)}
								/>
							))
						)}
					</Space>
				</div>
			) : history.length > 0 ? (
				<div>
					<Typography.TitleMobile
						tag="h5"
						view="xsmall"
						weight="bold"
						style={{ padding: "16px 0" }}
					>
						История
					</Typography.TitleMobile>

					<Divider />

					<Space size={0} fullWidth>
						{history.map((venue) => (
							<PlaceItem
								key={venue.venueId}
								name={venue.venueName}
								address={venue.venueName}
								selected={selectedPlaces.some(
									({ venueId }) => venueId === venue.venueId,
								)}
								onClick={() => onSelectPlace(venue)}
							/>
						))}
					</Space>
				</div>
			) : (
				<div>
					<Typography.TitleMobile
						tag="h5"
						view="xsmall"
						weight="bold"
						style={{ padding: "16px 0" }}
					>
						{`Популярные места в "${city.cityName}"`}
					</Typography.TitleMobile>

					<Divider />

					<Space size={0} fullWidth>
						{popular.map((venue) => (
							<PlaceItem
								key={venue.venueId}
								name={venue.venueName}
								address={venue.venueName}
								selected={selectedPlaces.some(
									({ venueId }) => venueId === venue.venueId,
								)}
								onClick={() => onSelectPlace(venue)}
							/>
						))}
					</Space>
				</div>
			)}
		</div>
	);
};
