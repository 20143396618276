import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@alfalab/core-components/button";
import { Gap } from "@alfalab/core-components/gap";
import { Space } from "@alfalab/core-components/space";
import { Badge } from "@alfalab/core-components/badge";
import { CalendarMobile, usePeriod } from "@alfalab/core-components/calendar";
import { Typography } from "@alfalab/core-components/typography";
import { PureCell } from "@alfalab/core-components/pure-cell";
import { BottomSheet } from "@alfalab/core-components/bottom-sheet";
import { ChevronRightShiftRightMIcon } from "@alfalab/icons-glyph/ChevronRightShiftRightMIcon";

import { TagList } from "@/components/tag-list/TagList";
import { useActions } from "@/lib/actions/ActionsContext";
import { placesHistory } from "@/lib/placesHistory";
import { citiesHistory } from "@/lib/citiesHistory";
import { useToggle } from "@/lib/hooks";
import { City, Kind, Venue } from "@/lib/types";

import { SearchFilter } from "./search-filter/SearchFilter";
import { CityFilter } from "./city-filter/CityFilter";
import { PlacesFilter } from "./places-filter/PlacesFilter";
import { KindFilter } from "./kind-filter/KindFilter";
import { GenresFilter } from "./genres-filter/GenresFilter";

import styles from "./ActionFilters.module.css";

enum FILTERS_VIEW {
	MAIN = "MAIN",
	CITY = "CITY",
	KIND = "KIND",
	GENRES = "GENRES",
	PLACES = "PLACES",
	DATES = "DATES",
}

type MenuItemProps = {
	label: React.ReactNode;
	count?: number;
	onClick(): void;
};

const MenuItem: React.FC<MenuItemProps> = ({ label, count, onClick }) => {
	return (
		<button type="button" className={styles["menu-item"]} onClick={onClick}>
			<PureCell direction="horizontal" verticalPadding="default">
				<PureCell.Content>
					<PureCell.Main>
						<Typography.Text>{label}</Typography.Text>
					</PureCell.Main>

					<PureCell.Addon>
						{count ? (
							<Badge view="count" height={24} content={count} />
						) : (
							<ChevronRightShiftRightMIcon color="var(--color-light-graphic-secondary)" />
						)}
					</PureCell.Addon>
				</PureCell.Content>
			</PureCell>
		</button>
	);
};

type FiltersState = {
	// city: City | null;
	kind: Kind | null;
	venues: Venue[];
	genres: Record<number, boolean>;
	popular: boolean;
	month: boolean;
	weekend: boolean;
};

const initialLocalState: FiltersState = {
	// city: null,
	kind: null,
	venues: [],
	genres: {},
	popular: false,
	month: false,
	weekend: false,
};

export const ActionFilters: React.FC = () => {
	const {
		city,
		cities,
		kinds,
		genres,
		venues,
		actions,
		setFilters,
		filters,
		resetFilters,
		totalFiltersCount,
	} = useActions();
	const navigate = useNavigate();
	const [isModalOpen, toggleModalOpen] = useToggle(false);
	const [isCalendarOpen, toggleCalendarOpen] = useToggle(false);
	const [view, setView] = useState<FILTERS_VIEW>(FILTERS_VIEW.MAIN);
	const [localFilters, setLocalFilters] =
		useState<FiltersState>(initialLocalState);

	const {
		selectedFrom: selectedDateFrom,
		selectedTo: selectedDateTo,
		updatePeriod,
		resetPeriod,
	} = usePeriod({
		onPeriodChange(selectedFrom, selectedTo) {
			if (selectedFrom && selectedTo) {
				setFilters((state) => ({
					...state,
					date: [new Date(selectedFrom), new Date(selectedTo)],
				}));
			} else if (selectedFrom) {
				setFilters((state) => ({
					...state,
					date: [new Date(selectedFrom)],
				}));
			} else {
				setFilters((state) => ({
					...state,
					date: null,
				}));
			}
		},
	});

	const closeModal = useCallback(() => {
		toggleModalOpen(false);
		setView(FILTERS_VIEW.MAIN);
	}, [toggleModalOpen]);

	const apply = useCallback(() => {
		setFilters((state) => ({
			...state,
			...localFilters,
			date:
				selectedDateFrom && selectedDateTo
					? [new Date(selectedDateFrom), new Date(selectedDateTo)]
					: state.date,
		}));

		placesHistory.addItems(localFilters.venues);

		closeModal();
	}, [localFilters, selectedDateFrom, selectedDateTo, setFilters, closeModal]);

	const reset = useCallback(() => {
		resetFilters();
		closeModal();
		resetPeriod();
		setLocalFilters(initialLocalState);
	}, [closeModal, resetFilters, resetPeriod, setLocalFilters]);

	const handleSelectCity = (city: City) => {
		setLocalFilters((state) => ({ ...state, city }));
	};

	const handleSelectPlace = (place: Venue) => {
		setLocalFilters((state) => {
			const isExists = state.venues.some(
				({ venueId }) => venueId === place.venueId,
			);

			const placesToUpdate = isExists
				? state.venues.filter(({ venueId }) => venueId !== place.venueId)
				: [...state.venues, place];

			return { ...state, venues: placesToUpdate };
		});
	};

	const handleSelectGenres = (genres: Record<number, boolean>) => {
		setLocalFilters((state) => ({
			...state,
			genres,
		}));
	};

	const handleSelectKind = (kind: Kind | null) => {
		setLocalFilters((state) => ({ ...state, kind }));
	};

	const resetGenres = () => {
		setLocalFilters((state) => ({ ...state, genres: {} }));
	};

	const title = useMemo(() => {
		switch (view) {
			case FILTERS_VIEW.MAIN: {
				return "Фильтры";
			}
			case FILTERS_VIEW.CITY: {
				return "Укажите город";
			}
			case FILTERS_VIEW.PLACES: {
				return "Выберите площадку";
			}
			case FILTERS_VIEW.KIND: {
				return "Выберите вид события";
			}
			case FILTERS_VIEW.GENRES: {
				return "Выберите жанр";
			}
		}
	}, [view]);

	const renderActionButton = useCallback(() => {
		switch (view) {
			case FILTERS_VIEW.MAIN: {
				return (
					<Space fullWidth size="s">
						<Button size="s" view="primary" block onClick={apply}>
							Показать
						</Button>
						{totalFiltersCount > 0 && (
							<Button size="s" block onClick={reset}>
								Сбросить все
							</Button>
						)}
					</Space>
				);
			}
			case FILTERS_VIEW.GENRES: {
				return (
					<Space fullWidth direction="horizontal" size="s">
						<Button size="s" view="primary" block onClick={apply}>
							Применить
						</Button>
						{Object.values(localFilters.genres).length > 0 && (
							<Button size="s" block onClick={resetGenres}>
								Сбросить
							</Button>
						)}
					</Space>
				);
			}
			case FILTERS_VIEW.KIND:
			case FILTERS_VIEW.PLACES: {
				return (
					<Button size="s" view="primary" block onClick={apply}>
						Применить
					</Button>
				);
			}
		}
	}, [view, apply, totalFiltersCount, reset, localFilters.genres]);

	const getDateString = useCallback((date: Date) => {
		if (!date) return "";

		const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
		const month =
			date.getMonth() + 1 < 10
				? `0${date.getMonth() + 1}`
				: date.getMonth() + 1;
		const year = date.getFullYear();

		return `${day}.${month}.${year}`;
	}, []);

	const selectedRange = useMemo(() => {
		if (selectedDateFrom && selectedDateTo) {
			const selectedFromDate = new Date(selectedDateFrom);
			const selectedToDate = new Date(selectedDateTo);

			return `${getDateString(selectedFromDate)} - ${getDateString(
				selectedToDate,
			)}`;
		}

		return null;
	}, [getDateString, selectedDateFrom, selectedDateTo]);

	return (
		<div>
			<SearchFilter
				actions={actions}
				city={city}
				filtersCounter={
					Object.values(filters.genres).length +
					filters.venues.length +
					(filters.kind ? 1 : 0) +
					(filters.popular ? 1 : 0) +
					(filters.weekend ? 1 : 0) +
					(filters.month ? 1 : 0)
				}
				calendarCounter={filters.date !== null ? 1 : 0}
				onClickSettings={() => navigate(`${location.pathname}/filters`)}
				onClickCalendar={() => navigate(`${location.pathname}/filters/date`)}
			/>

			<BottomSheet
				title={
					<Typography.TitleMobile view="xsmall" weight="bold" tag="h5">
						{title}
					</Typography.TitleMobile>
				}
				titleSize="compact"
				initialHeight="full"
				open={isModalOpen}
				swipeable
				hasCloser
				hasBacker={view !== FILTERS_VIEW.MAIN}
				onClose={closeModal}
				onBack={() => setView(FILTERS_VIEW.MAIN)}
				actionButton={renderActionButton()}
				stickyHeader
				usePortal
			>
				{view === FILTERS_VIEW.MAIN && (
					<div>
						<Gap size="m" />
						{/* <TagList
							tags={[
								{ label: "Ближайшие выходные", key: "weekend" },
								{ label: "Популярные", key: "popular" },
								{ label: "В этом месяце", key: "month" },
							]}
							selectedTags={{
								popular: localFilters.popular,
								weekend: localFilters.weekend,
								month: localFilters.month,
							}}
							onChange={(tags) => {
								setLocalFilters((state) => ({
									...state,
									...tags,
								}));
							}}
						/> */}
						{/* <Gap size="s" /> */}
						<MenuItem
							label={city.cityName || "Город"}
							onClick={() => setView(FILTERS_VIEW.CITY)}
						/>
						<MenuItem
							label="Вид события"
							count={localFilters.kind ? 1 : 0}
							onClick={() => setView(FILTERS_VIEW.KIND)}
						/>
						<MenuItem
							label="Жанры"
							count={
								Object.values(localFilters.genres).filter(
									(isChecked) => isChecked,
								).length
							}
							onClick={() => setView(FILTERS_VIEW.GENRES)}
						/>
						<MenuItem
							label="Площадки"
							count={localFilters.venues.length}
							onClick={() => setView(FILTERS_VIEW.PLACES)}
						/>
						<MenuItem
							label={
								selectedRange ? (
									<Typography.Text color="accent">
										{selectedRange}
									</Typography.Text>
								) : (
									"Выбрать даты"
								)
							}
							onClick={toggleCalendarOpen}
						/>
					</div>
				)}

				{view === FILTERS_VIEW.CITY && (
					<CityFilter
						cities={cities.filter(({ cityId }) => cityId !== -1)}
						handleSelectCity={handleSelectCity}
					/>
				)}

				{view === FILTERS_VIEW.KIND && (
					<KindFilter
						cityKinds={kinds}
						selectedKind={localFilters.kind}
						onSelectKind={handleSelectKind}
					/>
				)}

				{view === FILTERS_VIEW.GENRES && (
					<GenresFilter
						cityGenres={genres}
						selectedGenres={localFilters.genres || filters.genres}
						onSelectGenres={handleSelectGenres}
					/>
				)}

				{view === FILTERS_VIEW.PLACES && (
					<PlacesFilter
						city={city}
						cityVenues={venues}
						selectedPlaces={localFilters.venues || filters.venues}
						onSelectPlace={handleSelectPlace}
					/>
				)}
			</BottomSheet>

			<CalendarMobile
				open={isCalendarOpen}
				onClose={toggleCalendarOpen}
				title="Выберите дату"
				selectorView="month-only"
				selectedFrom={selectedDateFrom}
				selectedTo={selectedDateTo}
				onChange={updatePeriod}
				minDate={new Date().getTime()}
				allowSelectionFromEmptyRange
				shape="rectangular"
			/>
		</div>
	);
};
