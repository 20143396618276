import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@alfalab/core-components/typography";
import { IconButton } from "@alfalab/core-components/icon-button";
import { Button } from "@alfalab/core-components/button";
import { Space } from "@alfalab/core-components/space";
import { ChevronDownSIcon } from "@alfalab/icons-glyph/ChevronDownSIcon";
import CrossCircleMIcon from "@alfalab/icons-glyph/CrossCircleMIcon";

import { NonCriticalError } from "@/components/error/NonCriticalError";
import { Disclosure } from "@/components/disclosure/Disclosure";
import { useToggle } from "@/lib/hooks";
import { plural } from "@/lib/utils/plural";
import { formatPrice } from "@/lib/utils/formatPrice";
import { CartItem, useCart } from "@/lib/cart/CartContext";

import styles from "./ReservationSummary.module.css";

type ReservationSummaryProps = {
	onRemove?(item: CartItem): void;
	onCheckout(): void | Promise<void>;
};

export const ReservationSummary: React.FC<ReservationSummaryProps> = ({
	onRemove,
	onCheckout,
}) => {
	const params = useParams();
	const cart = useCart();
	const [isOpen, toggleOpen] = useToggle(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMesssage] = useState<string | null>(null);

	const cartItems = useMemo(() => {
		return Object.values(cart.items)
			.filter(({ eventId }) => eventId === Number(params.eventId))
			.reduce((acc, item) => {
				const flattenItems = [...new Array(item.qty)].map(() => item);

				return [...acc, ...flattenItems];
			}, [] as CartItem[]);
	}, [cart.items, params.eventId]);

	const handleToCheckout = () => {
		setLoading(true);
		Promise.resolve(onCheckout()).finally(() => setLoading(false));
	};

	if (cart.totalCount === 0) {
		return null;
	}

	return (
		<div>
			<div className={styles["container"]}>
				<div>
					<div className={styles["header"]}>
						<Typography.TitleMobile tag="h2" view="xsmall" weight="bold">
							Выбранные билеты
						</Typography.TitleMobile>

						<IconButton
							size="xxs"
							icon={ChevronDownSIcon}
							onClick={() => toggleOpen()}
							style={{
								transform: `rotate(${isOpen ? 180 : 0}deg)`,
							}}
						/>
					</div>

					<div style={{ maxHeight: 300 }}>
						<div className={styles["inner"]}>
							<div className={styles["inner-header"]}>
								<Typography.Text view="secondary-large" weight="bold">
									{cart.totalCount}{" "}
									{plural(["билет", "билета", "билетов"], cart.totalCount)}
								</Typography.Text>

								<Typography.Text view="secondary-large" weight="bold">
									{formatPrice({ amount: cart.totalSum, currency: "RUB" })}
								</Typography.Text>
							</div>

							<Disclosure open={isOpen} className={styles["inner-disclosure"]}>
								<Space size="s" fullWidth>
									{cartItems.map((ticket) => (
										<div key={ticket.key} className={styles["inner-row"]}>
											<div className={styles["inner-row-left"]}>
												<Typography.Text view="primary-medium">
													{ticket.name}
												</Typography.Text>
												{ticket.tariffName && (
													<Typography.Text
														view="primary-small"
														color="secondary"
													>
														{ticket.tariffName}
													</Typography.Text>
												)}
											</div>

											<div className={styles["inner-row-right"]}>
												<Typography.Text weight="bold">
													{formatPrice({
														amount: ticket.price,
														currency: "RUB",
													})}
												</Typography.Text>
												<IconButton
													view="tertiary"
													size="xs"
													icon={CrossCircleMIcon}
													onClick={() => {
														if (onRemove) {
															onRemove(ticket);
														}
														cart.decreaseItem(ticket.key);
													}}
												/>
											</div>
										</div>
									))}
								</Space>
							</Disclosure>
						</div>
					</div>
				</div>

				<NonCriticalError
					open={Boolean(errorMessage)}
					message={errorMessage}
					onClose={() => setErrorMesssage(null)}
					action={{
						label: "Продолжить",
						callback: () => setErrorMesssage(null),
					}}
				/>
			</div>

			<Button
				size="m"
				view="primary"
				block
				loading={isLoading}
				onClick={handleToCheckout}
				className={styles["checkout-button"]}
			>
				Перейти к оплате
			</Button>
		</div>
	);
};
