import { useMemo, useState } from "react";
import { Input } from "@alfalab/core-components/input";
import { Gap } from "@alfalab/core-components/gap";
import { Space } from "@alfalab/core-components/space";
import { Divider } from "@alfalab/core-components/divider";
import { Typography } from "@alfalab/core-components/typography";
import { PureCell } from "@alfalab/core-components/pure-cell";

import { City } from "@/lib/types";

import styles from "./CityFilter.module.css";

function getHighlightedText(text: string, highlight: string) {
	const parts = text.split(new RegExp(`^(${highlight})`, "gi"));

	return (
		<span>
			{" "}
			{parts.map((part, i) => (
				<span
					key={`${i}`}
					style={
						part.toLowerCase() === highlight.toLowerCase()
							? { fontWeight: "bold" }
							: {}
					}
				>
					{part}
				</span>
			))}{" "}
		</span>
	);
}

type CityFilterProps = {
	cities?: Array<City>;
	handleSelectCity(city: City): void;
};

export const CityFilter: React.FC<CityFilterProps> = ({
	cities = [],
	handleSelectCity,
}) => {
	const [query, setQuery] = useState("");

	const results = useMemo(() => {
		return cities.filter(({ cityName }) =>
			cityName.toLowerCase().startsWith(query.toLowerCase()),
		);
	}, [cities, query]);

	const history = useMemo(() => {
		return JSON.parse(
			localStorage.getItem("cities_history") || "[]",
		) as Array<City>;
	}, []);

	return (
		<div>
			<Input
				block
				placeholder="Город"
				size="s"
				clear={true}
				value={query}
				onChange={({ target }) => setQuery(target.value)}
				onClear={() => setQuery("")}
			/>

			{query !== "" ? (
				<div>
					<Gap size="s" />

					<Space size={0} fullWidth divider={<Divider />}>
						{results.map((city) => (
							<button
								key={city.cityId}
								type="button"
								className={styles["city-item"]}
								onClick={() => handleSelectCity(city)}
							>
								<PureCell verticalPadding="default">
									<PureCell.Content>
										<PureCell.Main>
											<Typography.Text>
												{getHighlightedText(city.cityName, query)}
											</Typography.Text>
										</PureCell.Main>

										<PureCell.Footer footerPadding="none">
											<Typography.Text view="primary-small" color="secondary">
												Россия
											</Typography.Text>
										</PureCell.Footer>
									</PureCell.Content>
								</PureCell>
							</button>
						))}
					</Space>
				</div>
			) : history.length > 0 ? (
				<div>
					<Typography.TitleMobile
						tag="h5"
						view="xsmall"
						weight="bold"
						style={{ padding: "16px 0" }}
					>
						История
					</Typography.TitleMobile>

					<Divider />

					<Space size={0} fullWidth divider={<Divider />}>
						{history.map((city) => (
							<button
								key={city.cityId}
								type="button"
								className={styles["city-item"]}
								onClick={() => handleSelectCity(city)}
							>
								<PureCell verticalPadding="default">
									<PureCell.Content>
										<PureCell.Main>
											<Typography.Text>{city.cityName}</Typography.Text>
										</PureCell.Main>

										<PureCell.Footer footerPadding="none">
											<Typography.Text view="primary-small" color="secondary">
												Россия
											</Typography.Text>
										</PureCell.Footer>
									</PureCell.Content>
								</PureCell>
							</button>
						))}
					</Space>
				</div>
			) : (
				<div>
					<Space size={0} fullWidth divider={<Divider />}>
						{cities.map((city) => (
							<button
								key={city.cityId}
								type="button"
								className={styles["city-item"]}
								onClick={() => handleSelectCity(city)}
							>
								<PureCell verticalPadding="default">
									<PureCell.Content>
										<PureCell.Main>
											<Typography.Text>{city.cityName}</Typography.Text>
										</PureCell.Main>

										<PureCell.Footer footerPadding="none">
											<Typography.Text view="primary-small" color="secondary">
												Россия
											</Typography.Text>
										</PureCell.Footer>
									</PureCell.Content>
								</PureCell>
							</button>
						))}
					</Space>
				</div>
			)}
		</div>
	);
};
