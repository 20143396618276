import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { Typography } from "@alfalab/core-components/typography";

import { Pagination, usePagination } from "@/components/pagination/Pagination";
import { useActions } from "@/lib/actions/ActionsContext";
import { Action, Venue } from "@/lib/types";

import { ActionItem } from "../action-item/ActionItem";

import styles from "./ActionList.module.css";

const ITEMS_PER_PAGE = 10;

export const ActionList: React.FC = () => {
	const { actions, city, totalFiltersCount } = useActions();
	const { scrollToItem } = usePagination();
	const [page, setPage] = useState(() => {
		const param = new URLSearchParams(window.location.search).get("item");

		return param ? Math.ceil(Number(param) / ITEMS_PER_PAGE) - 1 : 0;
	});
	const [items, setItems] = useState<Array<Action & { venues: Venue[] }>>([]);
	const [hasMore, setHasMore] = useState(true);
	const isInitialLoadRef = useRef(true);

	useEffect(() => {
		if (isInitialLoadRef.current === false) {
			const nextItems = actions.slice(0, ITEMS_PER_PAGE - 1);

			setItems(nextItems);
			setHasMore(nextItems.length < actions.length);
			setPage(1);
		}
	}, [actions]);

	useEffect(() => {
		if (actions.length !== 0) {
			isInitialLoadRef.current = false;
		}
	}, [actions]);

	const loadFunc = useCallback(() => {
		const nextPage = page + 1;
		const nextItems = actions.slice(0, ITEMS_PER_PAGE * nextPage);

		setItems(nextItems);

		setHasMore(nextItems.length < actions.length);

		if (isInitialLoadRef.current === true) {
			scrollToItem();
		} else {
			setPage(nextPage);
		}
	}, [page, actions, scrollToItem]);

	return (
		<div>
			{actions.length === 0 ? (
				<Typography.Text className={styles["not-found-message"]}>
					Ничего ничего не найдено
				</Typography.Text>
			) : (
				<div>
					<div className={styles["header"]}>
						<Typography.TitleMobile
							tag="h2"
							view="small"
							weight="bold"
							className={styles["item-title"]}
						>
							{totalFiltersCount === 0
								? "Вам может понравиться"
								: "Результаты поиска"}
						</Typography.TitleMobile>
					</div>

					<InfiniteScroll
						pageStart={page}
						loadMore={loadFunc}
						hasMore={hasMore}
						threshold={300}
						loader={
							<Fragment key="loading">
								<ActionItem />
								<ActionItem />
							</Fragment>
						}
						className={styles["list"]}
					>
						{items.map((action, idx) => (
							<Pagination.Item key={action.actionId} id={String(idx + 1)}>
								<Link
									to={`/city/${city.cityId}/${action.venues[0].venueId}_${action.actionId}`}
								>
									<ActionItem
										data={{
											id: action.actionId,
											title: action.actionName,
											posterName: action.actionName,
											posterUrl: action.smallPosterKassir,
											venues: action.venues,
											cityId: city.cityId,
											age: action.age,
											kind: action.kindName,
											minPrice: action.minPrice,
											maxPrice: action.maxPrice,
											firstEventDate: new Date(action.from * 1000),
											lastEventDate: new Date(action.to * 1000),
											time: action.time,
											cashback: action.cashback,
										}}
									/>
								</Link>
							</Pagination.Item>
						))}
					</InfiniteScroll>
				</div>
			)}
		</div>
	);
};
