import { useNavigate } from "react-router-dom";
import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { Space } from "@alfalab/core-components/space";

import { Thumb } from "@/components/thumb/Thumb";
import { formatDateTime } from "@/lib/utils/formatDate";

import styles from "./TicketItem.module.css";

type TicketItemProps = {
	data: {
		orderId: number;
		title: string;
		date: Date;
	};
	hasActions?: boolean;
};

export const TicketItem: React.FC<TicketItemProps> = ({ data, hasActions }) => {
	const navigate = useNavigate();

	return (
		<div className={styles["container"]}>
			<Thumb
				src="https://api.bil24.pro:1240/image?type=smallPoster&actionId=93&hash=l2ptlhjq8c1ysg9nfv2kn2gb0"
				height={104}
				className={styles["image"]}
			/>
			<Typography.TitleMobile
				tag="h2"
				view="xsmall"
				weight="bold"
				className={styles["title"]}
			>
				{data.title}
			</Typography.TitleMobile>
			<Typography.Text
				view="primary-small"
				color="secondary"
				className={styles["date"]}
			>
				{formatDateTime(data.date, {
					day: "numeric",
					weekday: "long",
					month: "long",
					hour: "2-digit",
					minute: "2-digit",
				})}
			</Typography.Text>

			{hasActions && (
				<Space
					direction="horizontal"
					size="s"
					fullWidth
					className={styles["actions"]}
				>
					<Button
						size="xs"
						view="tertiary"
						onClick={() => navigate(`/tickets/${data.orderId}`)}
						style={{ width: "100%" }}
					>
						Показать
					</Button>
					<Button size="xs" view="tertiary" style={{ width: "100%" }}>
						Открыть в pdf
					</Button>
				</Space>
			)}
		</div>
	);
};
