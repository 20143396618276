import { useNavigate } from "react-router-dom";
import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { Skeleton } from "@alfalab/core-components/skeleton";

import styles from "./ActionItem.module.css";

import { Cashback } from "@/components/cashback/Cashback";
import { Thumb } from "@/components/thumb/Thumb";
import { formatString } from "@/lib/utils/formatString";
import { Venue } from "@/lib/types";

function getFormattedDate(args: {
	firstDate: Date;
	lastDate: Date;
	time?: string;
}): string {
	const formatterOptions: Intl.DateTimeFormatOptions = {
		month: "long",
		day: "numeric",
	};

	if (args.firstDate.getDay() === args.lastDate.getDay()) {
		const date = args.firstDate;

		if (args.time) {
			const [hour, min] = args.time.split(":");
			date.setHours(Number(hour), Number(min));
			formatterOptions.hour = "2-digit";
			formatterOptions.minute = "2-digit";
		}

		const formatter = new Intl.DateTimeFormat("ru", formatterOptions);

		return formatter.format(args.firstDate);
	} else {
		const formatter = new Intl.DateTimeFormat("ru", formatterOptions);

		return `${formatter.format(args.firstDate)} - ${formatter.format(
			args.lastDate,
		)}`;
	}
}

function getFormattedPrice(args: {
	minPrice: number;
	maxPrice: number;
}): string {
	const isRange = args.minPrice !== args.maxPrice;

	return isRange ? `От ${args.minPrice} ₽` : `${args.maxPrice} ₽`;
}

type ActionItemProps = {
	data?: {
		id: number;
		title: string;
		posterUrl: string;
		posterName: string;
		venues: Venue[];
		cityId: number;
		age: string;
		kind: string;
		minPrice: number;
		maxPrice: number;
		firstEventDate: Date;
		lastEventDate: Date;
		time: string;
		cashback: number;
	};
};

export const ActionItem: React.FC<ActionItemProps> = ({ data }) => {
	const navigate = useNavigate();

	const isLoading = data === undefined;

	return (
		<div className={styles["container"]}>
			<div>
				<div className={styles["image-container"]}>
					<Skeleton visible={isLoading}>
						<Thumb
							width={120}
							rounded="xl"
							src={data?.posterUrl}
							alt={data?.posterName || data?.title}
							style={{
								aspectRatio: "95 / 138",
							}}
						/>
					</Skeleton>
					{data && (
						<Cashback
							value={`${data.cashback}%`}
							className={styles["cashback"]}
						/>
					)}
				</div>
			</div>

			<div className={styles["right-side"]}>
				<div className={styles["content"]}>
					<Typography.TitleMobile
						view="xsmall"
						weight="bold"
						tag="h2"
						className={styles["title"]}
					>
						<Skeleton visible={isLoading}>
							{(data && formatString(data.title)) || "Заголовок"}
						</Skeleton>
					</Typography.TitleMobile>

					<Skeleton visible={isLoading} className={styles["date"]}>
						<Typography.Text view="primary-small">
							{data
								? getFormattedDate({
										firstDate: data.firstEventDate,
										lastDate: data.lastEventDate,
										time: data.time,
								  })
								: "Дата проведения"}
						</Typography.Text>
					</Skeleton>

					<Skeleton visible={isLoading} className={styles["location"]}>
						<Typography.Text view="secondary-medium" color="secondary">
							{(data && formatString(data.venues[0].venueName)) || "Место"}
						</Typography.Text>
					</Skeleton>

					{/* <Skeleton visible={isLoading} className={styles["type"]}>
						<Typography.Text
							view="secondary-small"
							tag="span"
							color="secondary"
						>
							{data?.kind || "Тип события"}
						</Typography.Text>
					</Skeleton> */}
				</div>

				<div
					className={styles["bottom-content"]}
					style={{ visibility: isLoading ? "hidden" : "visible" }}
				>
					<Button
						size="xs"
						view="primary"
						onClick={() =>
							data &&
							navigate(
								`/city/${data.cityId}/${data.venues[0].venueId}_${data.id}`,
							)
						}
					>
						{data
							? getFormattedPrice({
									minPrice: data.minPrice,
									maxPrice: data.maxPrice,
							  })
							: "-"}
					</Button>

					{/* <div className={styles["cashback"]}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							fill="none"
						>
							<title>Alfa</title>
							<path
								fill="#EF3124"
								fillRule="evenodd"
								d="M22.5 12.002c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5ZM11.988 7.313l-1.3 3.75h2.576l-1.228-3.75h-.048Zm.096-2.061c1.044 0 1.348.604 1.613 1.37l2.733 7.911h-2.023l-.613-1.88h-3.66l-.662 1.88H7.57l2.868-7.912c.278-.767.602-1.37 1.646-1.37ZM7.57 16.642h8.86v2.11H7.57v-2.11Z"
								clipRule="evenodd"
							/>
						</svg>

						<Typography.Text view="primary-small" style={{ maxWidth: "4em" }}>
							Кэшбэк 15%
						</Typography.Text>
					</div> */}
				</div>
			</div>
		</div>
	);
};
