import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@alfalab/core-components/typography";
import { Space } from "@alfalab/core-components/space";
import { Button } from "@alfalab/core-components/button";
import { PureCell } from "@alfalab/core-components/pure-cell";
import { Badge } from "@alfalab/core-components/badge";
import { Divider } from "@alfalab/core-components/divider";
import ChevronRightShiftIcon from "@alfalab/icons-glyph/ChevronRightShiftRightSIcon";

import { useActions } from "@/lib/actions/ActionsContext";
import { usePageSettings } from "@/lib/hooks";
import { formatDateTime } from "@/lib/utils/formatDate";

import styles from "./FiltersView.module.css";

function getSelectedDatesDescription(dates: [Date] | [Date, Date]) {
	const formatOptions = {
		day: "numeric",
		month: "short",
		year: "numeric",
	} as const;

	if (dates.length === 1) {
		return formatDateTime(dates[0], formatOptions);
	} else {
		return `${formatDateTime(dates[0], formatOptions)} - ${formatDateTime(
			dates[1],
			formatOptions,
		)}`;
	}
}

type MenuItemProps = {
	label: React.ReactNode;
	description?: string;
	count?: number;
	onClick(): void;
};

const MenuItem: React.FC<MenuItemProps> = ({
	label,
	description,
	count,
	onClick,
}) => {
	return (
		<button type="button" className={styles["menu-item"]} onClick={onClick}>
			<PureCell direction="horizontal" verticalPadding="default">
				<PureCell.Content>
					<PureCell.Main>
						<Typography.Text view="primary-small">{label}</Typography.Text>
						{description && (
							<Typography.Text view="secondary-large" color="secondary">
								{description}
							</Typography.Text>
						)}
					</PureCell.Main>

					<PureCell.Addon verticalAlign="center">
						{count ? (
							<Badge view="count" height={20} content={count} />
						) : (
							<ChevronRightShiftIcon color="var(--color-light-graphic-secondary)" />
						)}
					</PureCell.Addon>
				</PureCell.Content>
			</PureCell>
		</button>
	);
};

type Tags = {
	popular: boolean;
	month: boolean;
	weekend: boolean;
};

type FiltersViewProps = {
	pageId: number;
};

export const FiltersView: React.FC<FiltersViewProps> = ({ pageId }) => {
	const navigate = useNavigate();
	const { city, filters, setFilters, resetFilters, totalFiltersCount } =
		useActions();
	const [tags, setTags] = useState<Tags>({
		month: filters.month,
		popular: filters.popular,
		weekend: filters.weekend,
	});

	usePageSettings({
		pageId,
		pageTitle: "Фильтры",
	});

	const apply = useCallback(() => {
		setFilters((state) => ({
			...state,
			...tags,
		}));

		navigate(`/city/${city.cityId}`);
	}, [tags, setFilters]);

	const reset = useCallback(() => {
		resetFilters();
		setTags({
			month: false,
			popular: false,
			weekend: false,
		});
	}, [resetFilters, setFilters]);

	return (
		<div className={styles["container"]}>
			<div>
				{/* <TagList
					tags={[
						{ label: "Ближайшие выходные", key: "weekend" },
						{ label: "Популярные", key: "popular" },
						{ label: "В этом месяце", key: "month" },
					]}
					selectedTags={tags}
					onChange={(tags) => setTags((state) => ({ ...state, ...tags }))}
				/> */}

				<Space
					size="s"
					fullWidth
					divider={<Divider />}
					className={styles["menu"]}
				>
					<MenuItem
						label={city.cityName || "Город"}
						onClick={() => navigate(`${location.pathname}/city`)}
					/>
					<MenuItem
						label="Вид события"
						description={filters.kind?.kindName}
						onClick={() => navigate(`${location.pathname}/kind`)}
					/>
					<MenuItem
						label="Жанры"
						count={
							Object.values(filters.genres).filter((isChecked) => isChecked)
								.length
						}
						onClick={() => navigate(`${location.pathname}/genres`)}
					/>
					<MenuItem
						label="Площадки"
						count={filters.venues.length}
						onClick={() => navigate(`${location.pathname}/venues`)}
					/>
					<MenuItem
						label="Дата проведения"
						description={
							filters.date
								? getSelectedDatesDescription(filters.date)
								: undefined
						}
						onClick={() => navigate(`${location.pathname}/date`)}
					/>
				</Space>
			</div>

			<Space fullWidth size="s" className={styles["button-group"]}>
				<Button size="s" view="primary" block onClick={apply}>
					Показать
				</Button>
				{totalFiltersCount > 0 && (
					<Button size="s" block onClick={reset}>
						Сбросить все
					</Button>
				)}
			</Space>
		</div>
	);
};
