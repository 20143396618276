import { Fragment, useMemo, useRef, useState } from "react";
import { Input } from "@alfalab/core-components/input";
import { Badge } from "@alfalab/core-components/badge";
import { Typography } from "@alfalab/core-components/typography";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";
import { Space } from "@alfalab/core-components/space";
import { Divider } from "@alfalab/core-components/divider";
import { IconButton } from "@alfalab/core-components/icon-button";
import { BaseModal } from "@alfalab/core-components/base-modal";
import { MagnifierMIcon } from "@alfalab/icons-glyph/MagnifierMIcon";
import { ArrowLeftMIcon } from "@alfalab/icons-glyph/ArrowLeftMIcon";
import { SlidersMIcon } from "@alfalab/icons-glyph/SlidersMIcon";
import { CalendarMIcon } from "@alfalab/icons-glyph/CalendarMIcon";

import { MAX_SEARCH_RESULTS } from "@/lib/constants";
import { formatString } from "@/lib/utils/formatString";
import { useToggle } from "@/lib/hooks";
import { Action, City } from "@/lib/types";

import styles from "./SearchFilter.module.css";
import { Link } from "react-router-dom";

function getHighlightedText(text: string, highlight: string) {
	const parts = text.split(new RegExp(`( |^|-|«)(${highlight})`, "gi"));

	return (
		<Fragment>
			{" "}
			{parts.map((part, idx) => (
				<span
					key={part + idx}
					style={
						part.toLowerCase() === highlight.toLowerCase()
							? { fontWeight: "bold" }
							: {}
					}
				>
					{part}
				</span>
			))}{" "}
		</Fragment>
	);
}

type SearchFilterProps = {
	actions: Action[];
	city: City;
	filtersCounter: number;
	calendarCounter: number;
	onClickSettings(): void;
	onClickCalendar(): void;
};

export const SearchFilter: React.FC<SearchFilterProps> = ({
	actions,
	city,
	filtersCounter,
	calendarCounter,
	onClickSettings,
	onClickCalendar,
}) => {
	const [isSearchOpen, toggleSearchOpen] = useToggle(false);
	const [query, setQuery] = useState("");
	const searchInputRef = useRef<HTMLInputElement | null>(null);

	const searchResults = useMemo(() => {
		const rx = new RegExp(`( |^|-|«)${query.toLowerCase()}`, "gi");

		return query !== ""
			? actions
					.filter(({ actionName }) => {
						return actionName
							.toLowerCase()
							.split(" ")
							.some((term) => rx.test(term));
					})
					.slice(-MAX_SEARCH_RESULTS)
					.map((action) => ({
						...action,
						actionName: formatString(action.actionName),
					}))
			: [];
	}, [actions, query]);

	return (
		<div className={styles["container"]}>
			<div className={styles["search-section"]}>
				<Input
					size="s"
					block
					placeholder={city ? `Поиск в ${city.cityName}` : "Поиск"}
					leftAddons={
						<MagnifierMIcon color="var(--color-dark-graphic-secondary)" />
					}
					onFocus={() => toggleSearchOpen(true)}
					readOnly
					value={query}
					onChange={({ target }) => setQuery(target.value)}
				/>

				<div className={styles["filter-buttons"]}>
					<SuperEllipse
						size={40}
						backgroundColor="transparent"
						topAddons={
							<Badge view="count" height={16} content={filtersCounter} />
						}
					>
						<IconButton
							onClick={onClickSettings}
							icon={SlidersMIcon}
							size="xxs"
						/>
					</SuperEllipse>

					<SuperEllipse
						size={40}
						backgroundColor="transparent"
						topAddons={
							<Badge view="count" height={16} content={calendarCounter} />
						}
					>
						<IconButton
							onClick={onClickCalendar}
							icon={CalendarMIcon}
							size="xxs"
						/>
					</SuperEllipse>
				</div>
			</div>

			<BaseModal
				open={isSearchOpen}
				disableRestoreFocus
				onMount={() => searchInputRef.current?.focus()}
				className={styles["search-modal"]}
			>
				<Input
					ref={searchInputRef}
					placeholder={city ? `Поиск в ${city.cityName}` : "Поиск"}
					size="s"
					block
					clear
					leftAddons={
						<IconButton
							view="secondary"
							onClick={() => {
								setQuery("");
								toggleSearchOpen(false);
							}}
							icon={ArrowLeftMIcon}
							size="xxs"
							dataTestId="icon"
						/>
					}
					value={query}
					onChange={({ target }) => setQuery(target.value)}
					onClear={() => setQuery("")}
				/>

				{query !== "" && (
					<div className={styles["results-container"]}>
						{searchResults.length === 0 ? (
							<Typography.Text className={styles["not-found-message"]}>
								Ничего ничего не найдено
							</Typography.Text>
						) : (
							<Space size={0} fullWidth divider={<Divider />}>
								{searchResults.map((action) => (
									<Link
										key={action.actionId}
										className={styles["list-item"]}
										to={`/city/${city.cityId}/${action.venues[0].venueId}_${action.actionId}`}
									>
										<Typography.TitleMobile
											view="xsmall"
											weight="regular"
											tag="h5"
										>
											{action.actionName.split(" ").map((name, idx) => (
												<Fragment key={name + idx}>
													{getHighlightedText(name, query)}
												</Fragment>
											))}
										</Typography.TitleMobile>

										<Typography.Text view="primary-small" color="secondary">
											{Object.values(action.venues || {})[0].venueName}
										</Typography.Text>
									</Link>
								))}
							</Space>
						)}
					</div>
				)}
			</BaseModal>
		</div>
	);
};
