import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "@alfalab/core-components/input";
import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { CheckboxGroup } from "@alfalab/core-components/checkbox-group";
import { Checkbox } from "@alfalab/core-components/checkbox";
import MagnifierMIcon from "@alfalab/icons-glyph/MagnifierMIcon";

import { usePageSettings } from "@/lib/hooks";
import { useActions } from "@/lib/actions/ActionsContext";

import styles from "./GenresView.module.css";

type FiltersGenresViewProps = {
	pageId: number;
};

export const FiltersGenresView: React.FC<FiltersGenresViewProps> = ({
	pageId,
}) => {
	const navigate = useNavigate();
	const { city, genres: cityGenres, filters, setFilters } = useActions();
	const [query, setQuery] = useState("");
	const [genres, setGenres] = useState<Record<number, boolean>>(filters.genres);

	usePageSettings({
		pageId,
		pageTitle: "Выберите жанр",
	});

	const handleChange = useCallback(
		(payload: { name: string; checked: boolean }) => {
			const genresToUpdate = {
				...genres,
				[Number(payload.name)]: payload.checked,
			};

			if (!payload.checked) {
				delete genresToUpdate[Number(payload.name)];
			}

			setGenres(genresToUpdate);
		},
		[genres],
	);

	const clear = () => {
		setGenres({});
	};

	const apply = () => {
		setFilters({
			...filters,
			genres,
		});

		navigate(`/city/${city.cityId}`);
	};

	return (
		<div className={styles["container"]}>
			<div className={styles["content"]}>
				<Input
					placeholder="Название"
					size="s"
					block
					leftAddons={
						<MagnifierMIcon color="var(--color-dark-graphic-secondary)" />
					}
					clear={true}
					value={query}
					onChange={(_, { value }) => setQuery(value)}
					onClear={() => setQuery("")}
				/>

				<div className={styles["list"]}>
					{cityGenres.length === 0 ? (
						<Typography.Text>Нет жанров</Typography.Text>
					) : (
						<CheckboxGroup
							onChange={(_, { name, checked }) => {
								if (name) {
									handleChange({ name, checked });
								}
							}}
						>
							{cityGenres.map(({ genreId, genreName }) => (
								<Checkbox
									key={genreId}
									size="m"
									label={genreName}
									name={genreId.toString()}
									checked={genres[genreId] ?? false}
								/>
							))}
						</CheckboxGroup>
					)}
				</div>
			</div>

			<div className={styles["button-group"]}>
				{Object.values(genres).filter((val) => val === true).length > 0 && (
					<Button view="secondary" size="s" block onClick={clear}>
						Сбросить
					</Button>
				)}
				<Button view="primary" size="s" block onClick={apply}>
					Применить
				</Button>
			</div>
		</div>
	);
};
