import { Typography } from "@alfalab/core-components/typography";
import { IconButton } from "@alfalab/core-components/icon-button";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";
import { PinLocationMIcon } from "@alfalab/icons-glyph/PinLocationMIcon";
import { RouteLineMIcon } from "@alfalab/icons-glyph/RouteLineMIcon";

import { formatString } from "@/lib/utils/formatString";
import { ActionExt } from "@/lib/types";

import styles from "./PlaceCard.module.css";

type PlaceCardProps = {
	data: ActionExt;
};

export const PlaceCard: React.FC<PlaceCardProps> = ({ data }) => {
	const handleClickRoutes = () => {
		window.open(
			`https://yandex.ru/maps/?ll=${Number(data.geoLon)}%2C${Number(
				data.geoLat,
			)}&mode=routes&rtext=~${Number(data.geoLat)}%2C${Number(
				data.geoLon,
			)}&rtt=auto&ruri=~&z=15`,
		);
	};

	return (
		<div>
			<Typography.TitleMobile view="xsmall" tag="h3" weight="bold">
				Место проведения
			</Typography.TitleMobile>

			<div className={styles["heading"]}>
				<PinLocationMIcon
					width={32}
					height={32}
					color="var(--color-light-graphic-secondary)"
				/>

				<div>
					<Typography.Text view="secondary-large" className={styles["name"]}>
						{formatString(data.venueName)}
					</Typography.Text>

					<Typography.Text
						view="secondary-medium"
						color="secondary"
						className={styles["address"]}
					>
						{data.address}
					</Typography.Text>
				</div>
			</div>

			{/* <div className={styles["content"]}>
				
			</div> */}

			{/* <YMaps>
				<YMap
					className={styles["map"]}
					defaultState={{
						center: [Number(data.geoLat), Number(data.geoLon)],
						zoom: 15,
					}}
				>
					<Placemark geometry={[Number(data.geoLat), Number(data.geoLon)]} />
				</YMap>
			</YMaps> */}

			<div className={styles["map"]}>
				<img
					width="100%"
					src={`https://static-maps.yandex.ru/1.x/?ll=${Number(
						data.geoLon,
					)},${Number(data.geoLat)}&size=430,450&z=15&l=map&pt=${Number(
						data.geoLon,
					)},${Number(data.geoLat)},comma`}
					alt="map"
				/>

				<SuperEllipse
					size={48}
					backgroundColor="var(--color-static-bg-primary-light)"
					className={styles["routes-button"]}
				>
					<IconButton
						view="negative"
						size="xxs"
						icon={RouteLineMIcon}
						onClick={handleClickRoutes}
					/>
				</SuperEllipse>
			</div>
		</div>
	);
};
