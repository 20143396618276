import { useMemo } from "react";
import { Typography } from "@alfalab/core-components/typography";
import { Space } from "@alfalab/core-components/space";
import cx from "classnames";

import { usePageSettings } from "@/lib/hooks/usePageSettings";

import { TicketItem } from "./order-item/TicketItem";

import styles from "./TicketView.module.css";

type Order = {
	orderId: number;
	title: string;
	date: Date;
};

const STUBS: Order[] = [
	{
		orderId: 1,
		title: "Вячеслав Бутусов. 40 лет юбилейный концерт",
		date: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 1),
	},
	{
		orderId: 2,
		title: "Внутри человека",
		date: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 4),
	},
	{
		orderId: 3,
		title: "Полёт на вертолёте",
		date: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
	},
	{
		orderId: 4,
		title: "Бунин. Тёмные аллеи",
		date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
	},
	{
		orderId: 5,
		title: "Аттракционы в Сочи Парк",
		date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 11),
	},
];

type TicketsViewProps = {
	pageId: number;
};

export const TicketsView: React.FC<TicketsViewProps> = ({ pageId }) => {
	usePageSettings({
		pageId,
		pageTitle: "Список билетов",
	});

	const orders = useMemo(() => {
		return STUBS.reduce(
			(acc, item) => {
				if (item.date.getTime() > new Date().getTime()) {
					acc.upcoming.push(item);
				} else {
					acc.history.push(item);
				}

				return acc;
			},
			{ upcoming: [], history: [] } as { upcoming: Order[]; history: Order[] },
		);
	}, []);

	return (
		<div>
			<div className={styles["container"]}>
				<div className={styles["header"]}>
					<Typography.TitleMobile tag="h1" view="small" weight="bold">
						Предстоящие события
					</Typography.TitleMobile>
					<Typography.Text color="accent">
						({orders.upcoming.length})
					</Typography.Text>
				</div>

				<Space direction="vertical" fullWidth size="l">
					{orders.upcoming.map((order) => (
						<TicketItem key={order.orderId} data={order} hasActions />
					))}
				</Space>
			</div>

			<div className={cx(styles["container"], styles["history"])}>
				<div className={styles["header"]}>
					<Typography.TitleMobile tag="h1" view="small" weight="bold">
						История покупок
					</Typography.TitleMobile>
					<Typography.Text color="secondary">
						({orders.history.length})
					</Typography.Text>
				</div>

				<Space
					direction="vertical"
					fullWidth
					size="l"
					className={styles["history-list"]}
				>
					{orders.history.map((order) => (
						<TicketItem key={order.orderId} data={order} />
					))}
				</Space>
			</div>
		</div>
	);
};
