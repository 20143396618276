import { Fragment, Suspense, useEffect, useMemo, useState } from "react";
import {
	useParams,
	useNavigate,
	Await,
	useRouteLoaderData,
	ActionFunction,
} from "react-router-dom";
import { Space } from "@alfalab/core-components/space";
import { TabsDesktop, Tab } from "@alfalab/core-components/tabs/desktop";

import { NonCriticalError } from "@/components/error/NonCriticalError";
import { AuthStatus, CartItemType, useCart } from "@/lib/cart/CartContext";
import { usePageSettings } from "@/lib/hooks";
import { apiService } from "@/lib/api";
import { APIError } from "@/lib/error";
import { ActionExt, ActionEvent, GetActionResult } from "@/lib/types";

import { EventDetails } from "./event-details/EventDetails";
import { Category } from "./category/Category";
import { Schema } from "./schema/Schema";
import { ReservationSummary } from "./summary/ReservationSummary";
import { AuthModal } from "./auth-modal/AuthModal";

import styles from "./ReservationView.module.css";

enum TabId {
	Category = "Category",
	Schema = "Schema",
}

type ReservationTabsProps = {
	data: ActionExt & { event: ActionEvent; schema?: string };
};

const ReservationTabs: React.FC<ReservationTabsProps> = ({ data }) => {
	const navigate = useNavigate();
	const cart = useCart();
	const [selectId, setSelected] = useState<TabId>(TabId.Category);
	const [showAuth, setShowAuth] = useState(false);
	const [error, setError] = useState<APIError | null>(null);

	const hasCategories = data.event.categoryLimitList.length > 0;
	const hasSchema = Boolean(data.event.placementUrl);

	const toCheckout = () => {
		const cartItems = Object.values(cart.items);
		const categoryItems = cartItems.filter(
			({ type }) => type === CartItemType.Category,
		);
		const seatItems = cartItems.filter(
			({ type }) => type === CartItemType.Seat,
		);

		return apiService
			.unreserveAll()
			.then(() =>
				apiService.reserve({
					categories: categoryItems.map(({ id, tariffId, qty }) => ({
						cid: id,
						tid: tariffId,
						qty,
					})),
					seats: seatItems.map(({ id, tariffId }) => ({
						sid: id,
						tid: tariffId,
					})),
				}),
			)
			.then(() => navigate("/checkout"))
			.catch((err: APIError) => {
				if (err.code === "user_unauthorized") {
					setShowAuth(true);
				} else {
					setError(err);
				}
			});
	};

	// Change initial tab
	useEffect(() => {
		if (!hasCategories && hasSchema) {
			setSelected(TabId.Schema);
		}
	}, []);

	return (
		<Fragment>
			{hasCategories && hasSchema && (
				<TabsDesktop
					size="xxs"
					view="secondary"
					selectedId={selectId}
					onChange={(_, { selectedId }) =>
						setSelected(selectedId.toString() as TabId)
					}
					className={styles["tabs"]}
				>
					<Tab title="Входной билет" id={TabId.Category} />
					<Tab title="Выбор места" id={TabId.Schema} />
				</TabsDesktop>
			)}

			<div
				style={{
					flex: 1,
					position: "relative",
				}}
			>
				<div
					className={styles["list"]}
					style={{
						flexDirection: "column",
						visibility: selectId === TabId.Category ? "visible" : "hidden",
					}}
				>
					{selectId === TabId.Category && (
						<Space direction="vertical" size="l" fullWidth>
							<Space direction="vertical" size="s" fullWidth>
								{data.event.categoryLimitList.map((limitGroup, idx) => (
									<Space
										direction="vertical"
										size="s"
										fullWidth
										key={idx.toString()}
									>
										{limitGroup.categoryList.map((category) => (
											<Category
												key={category.categoryPriceId}
												limitId={idx.toString()}
												eventId={data.event.actionEventId}
												tariffPlanList={data.event.tariffPlanList}
												remainder={limitGroup.remainder}
												action={{
													name: data.actionName,
													poster: data.poster,
													address: data.address,
													date: new Date(data.event.timestamp * 1000),
												}}
												data={category}
											/>
										))}
									</Space>
								))}
							</Space>

							<ReservationSummary onCheckout={toCheckout} />
						</Space>
					)}
				</div>

				{data.schema && (
					<div
						style={{
							position: "absolute",
							flex: 1,
							flexDirection: "column",
							display: "flex",
							inset: 0,
							visibility: selectId === TabId.Schema ? "visible" : "hidden",
						}}
					>
						<Schema
							eventId={data.event.actionEventId}
							schema={data.schema}
							action={{
								name: data.actionName,
								poster: data.poster,
								address: data.address,
								date: new Date(data.event.timestamp * 1000),
							}}
							onCheckout={toCheckout}
						/>
					</div>
				)}
			</div>

			<AuthModal open={showAuth} onClose={() => setShowAuth(false)} />

			<NonCriticalError
				open={Boolean(error)}
				message={error?.advice}
				onClose={() => setError(null)}
				action={{
					label: "Вернуться",
					callback: () =>
						navigate("../../", { replace: true, relative: "path" }),
				}}
			/>
		</Fragment>
	);
};

type ReservationProps = {
	data?: ActionExt;
	pending?: boolean;
};

const Reservation: React.FC<ReservationProps> = ({ data, pending = false }) => {
	const params = useParams();
	const [actionData, setActionData] = useState<
		(ActionExt & { event: ActionEvent; schema?: string }) | null
	>(null);

	const eventDetails = useMemo(() => {
		if (actionData) {
			return {
				address: actionData.address,
				title: actionData.actionName,
				poster: actionData.poster,
				date: new Date(actionData.event.timestamp * 1000),
			};
		} else {
			return undefined;
		}
	}, [actionData]);

	useEffect(() => {
		if (data) {
			const event = Object.values(data.events).find(
				({ actionEventId }) => actionEventId === Number(params.eventId),
			);

			if (!event) {
				throw new Error("Сеанс не найден");
			}

			if (event.placementUrl) {
				apiService
					.getSchema({ eid: Number(params.eventId) })
					.then((schema) => setActionData({ ...data, event, schema }));
			} else {
				setActionData({ ...data, event });
			}
		} else {
			return undefined;
		}
	}, [data]);

	return (
		<Fragment>
			<div className={styles["container"]}>
				<EventDetails data={eventDetails} />

				{actionData && !pending && <ReservationTabs data={actionData} />}
			</div>
		</Fragment>
	);
};

type ActionData = {
	action: Promise<GetActionResult>;
};

type ReservationViewProps = {
	pageId: number;
};

export const ReservationView: React.FC<ReservationViewProps> = ({ pageId }) => {
	const params = useParams();
	const data = useRouteLoaderData("action") as ActionData;
	const cart = useCart();
	const [isPending, setPending] = useState(false);

	usePageSettings({
		pageId,
		pageTitle: "Выбор билета",
	});

	// Remove old cart items and reservations
	useEffect(() => {
		const oldCartItems = Object.values(cart.items).filter(
			({ eventId }) => eventId !== Number(params.eventId),
		);

		if (oldCartItems.length > 0) {
			setPending(true);

			if (cart.authStatus === AuthStatus.AUTH) {
				apiService
					.unreserveAll()
					.then(() => cart.removeItems())
					.finally(() => setPending(false));
			} else {
				cart.removeItems();
				setPending(false);
			}
		}
	}, []);

	return (
		<Suspense fallback={<Reservation />}>
			<Await resolve={data.action}>
				{(action: ActionExt) => (
					<Reservation data={action} pending={isPending} />
				)}
			</Await>
		</Suspense>
	);
};

export const reservationAction: ActionFunction = async ({ request }) => {
	const formData = await request.formData();
	const email = formData.get("email");

	return apiService
		.auth({ email: String(email) })
		.catch((err: APIError) => ({ status: "Failed", error: err }))
		.then(() => ({ status: "Success" }));
};
