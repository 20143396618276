import { useMemo, useState } from "react";
import { ActionFunction, redirect, useNavigation, useSubmit } from "react-router-dom";
import { Input } from "@alfalab/core-components/input";
import { Space } from "@alfalab/core-components/space";
import { PureCell } from "@alfalab/core-components/pure-cell";
import { Typography } from "@alfalab/core-components/typography";
import { Divider } from "@alfalab/core-components/divider";
import MagnifierMIcon from "@alfalab/icons-glyph/MagnifierMIcon";

import { usePageSettings } from "@/lib/hooks";
import { useActions } from "@/lib/actions/ActionsContext";
import { citiesHistory } from "@/lib/citiesHistory";
import { apiService } from "@/lib/api";
import { City } from "@/lib/types";

import styles from "./CityView.module.css";

function getHighlightedText(text: string, highlight: string) {
	const parts = text.split(new RegExp(`^(${highlight})`, "gi"));

	return (
		<span>
			{" "}
			{parts.map((part, i) => (
				<span
					key={`${i}`}
					style={
						part.toLowerCase() === highlight.toLowerCase()
							? { fontWeight: "bold" }
							: {}
					}
				>
					{part}
				</span>
			))}{" "}
		</span>
	);
}

type FiltersCityViewProps = {
	pageId: number;
};

export const FiltersCityView: React.FC<FiltersCityViewProps> = ({ pageId }) => {
	const submit = useSubmit();
	const { cities } = useActions();
	const [query, setQuery] = useState("");

	usePageSettings({
		pageId,
		pageTitle: "Города",
	});

	const selectCity = (city: City) => {
		citiesHistory.setItem(city);

		apiService.setCity({ cityId: city.cityId }).then(() => {
			location.href = `/city/${city.cityId}`;
		})
	};

	const results = useMemo(() => {
		return cities.filter(({ cityName }) =>
			cityName.toLowerCase().startsWith(query.toLowerCase()),
		);
	}, [cities, query]);

	const history = useMemo(() => {
		return citiesHistory.getItems();
	}, []);

	return (
		<div className={styles["container"]}>
			<Input
				placeholder="Город"
				size="s"
				block
				leftAddons={
					<MagnifierMIcon color="var(--color-dark-graphic-secondary)" />
				}
				clear={true}
				value={query}
				onChange={({ target }) => setQuery(target.value)}
				onClear={() => setQuery("")}
				className={styles["input"]}
			/>

			<div className={styles["content"]}>
				{query !== "" ? (
					<Space size={0} fullWidth divider={<Divider />}>
						{results.map((city) => (
							<button
								key={city.cityId}
								type="button"
								className={styles["city-item"]}
								onClick={() => selectCity(city)}
							>
								<PureCell verticalPadding="default">
									<PureCell.Content>
										<PureCell.Main>
											<Typography.Text>
												{getHighlightedText(city.cityName, query)}
											</Typography.Text>
										</PureCell.Main>

										<PureCell.Footer footerPadding="none">
											<Typography.Text view="primary-small" color="secondary">
												Россия
											</Typography.Text>
										</PureCell.Footer>
									</PureCell.Content>
								</PureCell>
							</button>
						))}
					</Space>
				) : (
					<div>
						{history.length > 0 && (
							<div>
								<Typography.TitleMobile
									tag="h5"
									view="xsmall"
									weight="bold"
									style={{ padding: "16px 0" }}
								>
									История
								</Typography.TitleMobile>

								<Divider />

								<Space size={0} fullWidth divider={<Divider />}>
									{history.map((city) => (
										<button
											key={city.cityId}
											type="button"
											className={styles["city-item"]}
											onClick={() => selectCity(city)}
										>
											<PureCell verticalPadding="default">
												<PureCell.Content>
													<PureCell.Main>
														<Typography.Text>{city.cityName}</Typography.Text>
													</PureCell.Main>

													<PureCell.Footer footerPadding="none">
														<Typography.Text
															view="primary-small"
															color="secondary"
														>
															Россия
														</Typography.Text>
													</PureCell.Footer>
												</PureCell.Content>
											</PureCell>
										</button>
									))}
								</Space>
							</div>
						)}

						<Typography.TitleMobile
							tag="h5"
							view="xsmall"
							weight="bold"
							style={{ padding: "16px 0" }}
						>
							Все города
						</Typography.TitleMobile>

						<Divider />

						<Space size={0} fullWidth divider={<Divider />}>
							{cities.map((city) => (
								<button
									key={city.cityId}
									type="button"
									className={styles["city-item"]}
									onClick={() => selectCity(city)}
								>
									<PureCell verticalPadding="default">
										<PureCell.Content>
											<PureCell.Main>
												<Typography.Text>{city.cityName}</Typography.Text>
											</PureCell.Main>

											<PureCell.Footer footerPadding="none">
												<Typography.Text view="primary-small" color="secondary">
													Россия
												</Typography.Text>
											</PureCell.Footer>
										</PureCell.Content>
									</PureCell>
								</button>
							))}
						</Space>
					</div>
				)}
			</div>
		</div>
	);
};
