import { useEffect, useState } from "react";
import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";

import { usePageSettings } from "@/lib/hooks";
// import { randomNumber } from "@/lib/utils/randomNumber";
import { API_ENDPOINT } from "@/lib/constants";

import image from "@/assets/images/onboarding.png";

import styles from "./Onboarding.module.css";

type OnboardingViewProps = {
	pageId: number;
};

export const OnboardingView: React.FC<OnboardingViewProps> = ({ pageId }) => {
	const [nextLink, setNextLink] = useState();
	const [isLoading, setLoading] = useState(true);

	usePageSettings({
		pageId,
	});

	const handleClickNext = () => {
		if (nextLink) {
			location.replace(nextLink);
		}
	};

	useEffect(() => {
		fetch(`${API_ENDPOINT}?theme=light`, {
			method: "POST",
			body: JSON.stringify({
				id: 777, // Math.floor(randomNumber(1, 999)),
			}),
		})
			.then((res) => res.json())
			.then(({ data }) => setNextLink(data.url))
			.finally(() => setLoading(false));
	}, []);

	return (
		<div className={styles["container"]}>
			<div>
				<Typography.TitleMobile tag="h1" view="medium" weight="bold">
					Альфа-Афиша
				</Typography.TitleMobile>
				<div className={styles["content"]}>
					<Typography.Text tag="p" defaultMargins={false} view="primary-medium">
						Ходите на концерты, выставки, спектакли и лучшие события города с
						кэшбэком 15%
					</Typography.Text>
					<Typography.Text tag="p" view="primary-medium" color="secondary">
						Нажимая «Далее», я даю согласие на переход в сервис партнёра банка.
					</Typography.Text>
				</div>
			</div>

			<div className={styles["image-container"]}>
				<img
					width="100%"
					src={image}
					alt="onboarding"
					className={styles["image"]}
				/>
			</div>

			<Button
				view="primary"
				size="m"
				loading={isLoading}
				onClick={handleClickNext}
				className={styles["button"]}
			>
				Далее
			</Button>
		</div>
	);
};
