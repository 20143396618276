import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CalendarMobile, usePeriod } from "@alfalab/core-components/calendar";

import { useActions } from "@/lib/actions/ActionsContext";
import { usePageSettings } from "@/lib/hooks";

type FiltersDateViewProps = {
	pageId: number;
};

export const FiltersDateView: React.FC<FiltersDateViewProps> = ({ pageId }) => {
	const navigate = useNavigate();
	const { city, filters, setFilters } = useActions();
	const [date, setDate] = useState<[Date] | [Date, Date] | null>(filters.date);
	const {
		selectedFrom: selectedDateFrom,
		selectedTo: selectedDateTo,
		updatePeriod,
	} = usePeriod({
		onPeriodChange(selectedFrom, selectedTo) {
			if (selectedFrom && selectedTo) {
				setDate([new Date(selectedFrom), new Date(selectedTo)]);
			} else if (selectedFrom) {
				setDate([new Date(selectedFrom)]);
			} else {
				setDate(null);
			}
		},
		initialSelectedFrom: date?.[0].getTime(),
		initialSelectedTo: date?.[1]?.getTime(),
	});

	usePageSettings({
		pageId,
		pageTitle: "Выбор даты",
	});

	const handleChange = () => {
		setFilters({
			...filters,
			date,
		});
		navigate(`/city/${city.cityId}`);
	};

	return (
		<div>
			<CalendarMobile
				open
				onClose={handleChange}
				hasHeader={false}
				title="Выберите дату"
				selectorView="month-only"
				selectedFrom={selectedDateFrom}
				selectedTo={selectedDateTo}
				onChange={updatePeriod}
				minDate={new Date().getTime()}
				allowSelectionFromEmptyRange
			/>
		</div>
	);
};
