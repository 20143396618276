import { Typography } from "@alfalab/core-components/typography";
import { Space } from "@alfalab/core-components/space";
import Barcode from "react-barcode";

import { formatPrice } from "@/lib/utils/formatPrice";
import { Ticket } from "@/lib/types";

import styles from "./Summary.module.css";

type SummaryProps = {
	data: Ticket;
};

export const Summary: React.FC<SummaryProps> = ({ data }) => {
	return (
		<div>
			<Space size="m" fullWidth>
				<Space size={8} fullWidth>
					<div className={styles["row"]}>
						<div>
							<Typography.Text view="primary-small" weight="bold">
								{data.row
									? `${data.row} ряд, ${data.number} место`
									: data.categoryName}
							</Typography.Text>
							{/* {data.tariffPlanName && (
						<div className={styles["header-row"]}>
							<Typography.Text
								view="primary-small"
								color="secondary"
							>
								{data.tariffPlanName}
							</Typography.Text>
						</div>
					)} */}
						</div>

						<Typography.Text view="primary-small">
							{formatPrice({
								amount: data.price,
								currency: "RUB",
							})}
						</Typography.Text>
					</div>

					<div className={styles["row"]}>
						<Typography.Text view="primary-small">
							Сервисный сбор
						</Typography.Text>

						<Typography.Text view="primary-small">
							{formatPrice({
								amount: data.serviceCharge,
								currency: "RUB",
							})}
						</Typography.Text>
					</div>
				</Space>

				<div className={styles["row"]}>
					<Typography.Text view="primary-large" weight="bold">
						Итого
					</Typography.Text>
					<Typography.Text view="primary-large" weight="bold">
						{formatPrice({ currency: "RUB", amount: data.totalPrice })}
					</Typography.Text>
				</div>

				<div className={styles["footer-row"]}>
					<div className={styles["cashback"]}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							fill="none"
						>
							<title>Alfa</title>
							<path
								fill="#EF3124"
								fillRule="evenodd"
								d="M22.5 12.002c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5ZM11.988 7.313l-1.3 3.75h2.576l-1.228-3.75h-.048Zm.096-2.061c1.044 0 1.348.604 1.613 1.37l2.733 7.911h-2.023l-.613-1.88h-3.66l-.662 1.88H7.57l2.868-7.912c.278-.767.602-1.37 1.646-1.37ZM7.57 16.642h8.86v2.11H7.57v-2.11Z"
								clipRule="evenodd"
							/>
						</svg>
						<Typography.Text view="primary-small">Кэшбэк - 15%</Typography.Text>
					</div>

					<Typography.Text>
						{formatPrice({
							currency: "RUB",
							amount: (data.totalPrice / 100) * 15,
						})}
					</Typography.Text>
				</div>
			</Space>

			<div className={styles["barcode"]}>
				<Barcode value={data.barCodeNumber} height={60} />
			</div>
		</div>
	);
};
