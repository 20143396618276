import { RadioGroup } from "@alfalab/core-components/radio-group";
import { Radio } from "@alfalab/core-components/radio";

import { Kind } from "@/lib/types";

import styles from "./KindFilter.module.css";

type KindFilterProps = {
	cityKinds: Kind[];
	selectedKind: Kind | null;
	onSelectKind(kind: Kind | null): void;
};

export const KindFilter: React.FC<KindFilterProps> = ({
	cityKinds,
	selectedKind,
	onSelectKind,
}) => {
	return (
		<div className={styles["container"]}>
			<RadioGroup
				onChange={(_, { value }) => {
					onSelectKind(
						value !== "-1"
							? cityKinds.find(({ kindId }) => kindId === Number(value)) || null
							: null,
					);
				}}
				value={selectedKind !== null ? String(selectedKind.kindId) : "-1"}
			>
				<Radio label="Все" value={"-1"} size="m" />
				{cityKinds.map(({ kindId, kindName }) => (
					<Radio
						key={kindId}
						label={kindName}
						value={String(kindId)}
						size="m"
					/>
				))}
			</RadioGroup>
		</div>
	);
};
