import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { Typography } from "@alfalab/core-components/typography";
import { UserLineMIcon } from "@alfalab/icons-glyph/UserLineMIcon";
import cx from "classnames";

import { useActions } from "@/lib/actions/ActionsContext";
import { Kind } from "@/lib/types";

import styles from "./Header.module.css";

type NavItemProps = {
	label: string;
	active?: boolean;
	onClick(): void;
};

const NavItem: React.FC<NavItemProps> = ({ label, active, onClick }) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={cx(styles["tab"], active && styles["tab-active"])}
		>
			<Typography.Text view="primary-small" style={{ whiteSpace: "nowrap" }}>
				{label}
			</Typography.Text>
		</button>
	);
};

export const Header: React.FC = () => {
	const navigate = useNavigate();
	const { city, kinds, filters, setFilters } = useActions();

	const selectKind = (kind: Kind | null) => {
		setFilters((state) => ({ ...state, kind }));

		const nextRoute = `/city/${city.cityId}`;

		if (location.pathname !== nextRoute) {
			navigate(nextRoute);
		}
	};

	return (
		<div className={styles["container"]}>
			<ScrollContainer className={styles["scroll-container"]}>
				<Link to="/account" className={styles["account-link"]}>
					<UserLineMIcon width={32} color="var(--color-light-text-primary)" />
				</Link>

				<NavItem
					label={
						location.pathname === `/city/${city.cityId}` ? "Все" : city.cityName
					}
					active={!filters.kind}
					onClick={() => selectKind(null)}
				/>

				{kinds.map((kind) => (
					<NavItem
						key={kind.kindId}
						label={kind.kindName}
						active={filters.kind?.kindId === kind.kindId}
						onClick={() => selectKind(kind)}
					/>
				))}
			</ScrollContainer>
		</div>
	);
};
