import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { createContext } from "@/lib/utils/context";

type PaginationContext = {
	registerItem(itemId: string, itemRef: React.RefObject<HTMLDivElement>): void;
	unregisterItem(itemId: string): void;
	scrollToItem(itemId?: string): void;
};

const [usePagination, PaginationProvider] =
	createContext<PaginationContext>("Pagination");

type PaginationProps = {
	children: React.ReactNode;
};

export const PaginationRoot: React.FC<PaginationProps> = ({ children }) => {
	const itemsRef = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});
	const [item, setItem] = useState<string | null>(null);

	const registerItem = useCallback(
		(itemId: string, itemRef: React.RefObject<HTMLDivElement>) => {
			itemsRef.current[itemId] = itemRef;
		},
		[],
	);

	const unregisterItem = useCallback((itemId: string) => {
		if (itemsRef.current) {
			const newRef = { ...itemsRef.current };

			delete newRef[itemId];

			itemsRef.current = newRef;
		}
	}, []);

	const scrollToItem = useCallback(
		(itemId = item) => {
			if (itemsRef.current && itemId) {
				setTimeout(() => {
					const itemRef = itemsRef.current[itemId];
					itemRef.current?.scrollIntoView();
				}, 100);
			}
		},
		[item],
	);

	useEffect(() => {
		const handleScroll = () => {
			const srollTop = window.pageYOffset;

			if (srollTop > 100) {
				const item = Object.entries(itemsRef.current).find(
					([_, elementRef]) =>
						elementRef.current && elementRef.current.offsetTop > srollTop,
				);

				if (item) {
					const [currentItemId] = item;
					setItem(currentItemId);
				}
			} else {
				setItem(null);
			}
		};

		document.addEventListener("scroll", handleScroll);

		return () => {
			document.removeEventListener("scroll", handleScroll);
		};
	}, [setItem]);

	const ctxValue = useMemo(
		() => ({
			registerItem,
			unregisterItem,
			scrollToItem,
		}),
		[registerItem, unregisterItem, scrollToItem],
	);

	return <PaginationProvider value={ctxValue}>{children}</PaginationProvider>;
};

type PaginationItemProps = {
	id: string;
	children: React.ReactNode;
};

export const PaginationItem: React.FC<PaginationItemProps> = ({
	id,
	children,
}) => {
	const { registerItem, unregisterItem } = usePagination();
	const itemRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		registerItem(id, itemRef);

		return () => {
			unregisterItem(id);
		};
	}, [id, registerItem, unregisterItem]);

	return <div ref={itemRef}>{children}</div>;
};

export const Pagination = Object.assign(PaginationRoot, {
	Item: PaginationItem,
});

export { usePagination };
