import { useState, useMemo, useCallback } from "react";

import { createContext } from "@/lib/utils/context";
import {
	City,
	Venue,
	Kind,
	Action,
	GetAllActionsByCityResult,
} from "@/lib/types";

type FiltersState = {
	kind: Kind | null;
	venues: Venue[];
	genres: Record<number, boolean>;
	date: [Date, Date] | [Date] | null;
	search: string;
	popular: boolean;
	weekend: boolean;
	month: boolean;
};

type ActionsContext = {
	city: City;
	kinds: Array<GetAllActionsByCityResult["kinds"][number]>;
	genres: Array<GetAllActionsByCityResult["genres"][number]>;
	venues: Array<GetAllActionsByCityResult["venues"][number]>;
	actions: Array<
		Omit<Action, "venues"> & {
			venues: Array<{
				cityId: number;
				cityName: string;
				venueId: number;
				venueName: string;
				href: string;
			}>;
		}
	>;
	slider: Array<GetAllActionsByCityResult["slider"][number]>;
	selections: Array<GetAllActionsByCityResult["selections"][number]>;
	cities: Array<GetAllActionsByCityResult["cities"][number]>;
	filters: FiltersState;
	setFilters: React.Dispatch<React.SetStateAction<FiltersState>>;
	totalFiltersCount: number;
	totalResultsCount: number;
	resetFilters(): void;
};

const [useActions, ActionsProvider] = createContext<ActionsContext>("Actions");

const initialFiltersState: FiltersState = {
	kind: null,
	venues: [],
	genres: {},
	date: null,
	search: "",
	popular: false,
	month: false,
	weekend: false,
};

const DAY = 1000 * 60 * 60 * 24;

function isWeekend(date: Date): boolean {
	const now = new Date();

	const endDate = new Date(now.getTime() + (7 - now.getDay()) * DAY);
	endDate.setHours(23, 60, 0);

	const startDate = new Date(endDate.getTime() - DAY * 2);

	const eventTime = date.getTime();
	const startTime = startDate.getTime();
	const endTime = endDate.getTime();

	return startTime <= eventTime && eventTime < endTime;
}

function isInMonth(date: Date): boolean {
	const now = new Date();

	return (
		date.getMonth() === now.getMonth() &&
		date.getFullYear() === now.getFullYear()
	);
}

type WithActionsProps = {
	data: GetAllActionsByCityResult;
	children: React.ReactNode;
};

const WithActions: React.FC<WithActionsProps> = ({ data, children }) => {
	// const params = useParams();
	// const navigate = useNavigate();
	const [filters, setFilters] = useState<FiltersState>(initialFiltersState);

	const city = useMemo<City>(() => {
		return {
			cityId: data.cityId,
			cityName: data.cityName,
		};
	}, [data]);

	const filteredActions = useMemo(() => {
		return Object.values(data.actions)
			.filter((action) => {
				// venues filter
				if (
					filters.venues.length > 0 &&
					!filters.venues.some(({ venueId }) => {
						return Object.values(action.venues)
							.map(({ venueId }) => venueId)
							.includes(venueId);
					})
				) {
					return false;
				}

				// kind filter
				if (filters.kind && action.kindId !== filters.kind.kindId) {
					return false;
				}

				// genres filter
				const genresIds = Object.keys(filters.genres);

				if (
					genresIds.length > 0 &&
					!Object.keys(action.genres).some((id) => {
						return genresIds.includes(id);
					})
				) {
					return false;
				}

				// weekend filter
				if (
					filters.weekend === true &&
					!isWeekend(new Date(action.from * 1000))
				) {
					return false;
				}

				// month filter
				if (
					filters.weekend === true &&
					!isInMonth(new Date(action.from * 1000))
				) {
					return false;
				}

				// popular filter
				if (filters.popular === true) {
					return false;
				}

				// date filter
				if (filters.date) {
					const [dateFrom, dateTo] = filters.date;

					if (dateFrom && dateTo) {
						return (
							action.from * 1000 >= dateFrom.getTime() &&
							action.from * 1000 < dateTo.getTime()
						);
					} else {
						return action.from * 1000 >= dateFrom.getTime();
					}
				}

				return true;
			})
			.map((action) => ({
				...action,
				venues: Object.values(action.venues).filter(
					({ cityId }) => cityId === data.cityId,
				),
			}));
	}, [data, filters]);

	const totalFiltersCount = useMemo(() => {
		return (
			(filters.kind ? 1 : 0) +
			(filters.date ? 1 : 0) +
			(filters.popular ? 1 : 0) +
			(filters.weekend ? 1 : 0) +
			(filters.month ? 1 : 0) +
			filters.venues.length +
			Object.values(filters.genres).filter((isChecked) => isChecked).length
		);
	}, [filters]);

	const resetFilters = useCallback(() => {
		setFilters(initialFiltersState);
	}, []);

	// useEffect(() => {
	// 	if (filters.city?.cityId) {
	// 		const cityId = filters.city.cityId;

	// 		apiService.setCity({ cityId }).then(() => {
	// 			localStorage.setItem("city_id", String(cityId));
	// 		});

	// 		navigate(`/city/${cityId}`, { replace: true });
	// 	}
	// }, [filters.city?.cityId]);

	const ctxValue = useMemo<ActionsContext>(
		() => ({
			city,
			cities: data.cities,
			kinds: data.kinds,
			genres: data.genres,
			venues: data.venues,
			slider: data.slider,
			selections: data.selections,
			filters,
			setFilters,
			resetFilters,
			totalFiltersCount,
			totalResultsCount: filteredActions.length,
			actions: filteredActions,
		}),
		[
			city,
			filteredActions,
			filters,
			setFilters,
			resetFilters,
			totalFiltersCount,
			data,
		],
	);

	return <ActionsProvider value={ctxValue}>{children}</ActionsProvider>;
};

export { useActions, WithActions };
