import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@alfalab/core-components/typography";
import { Divider } from "@alfalab/core-components/divider";
import { Space } from "@alfalab/core-components/space";

import { useActions } from "@/lib/actions/ActionsContext";
import { groupBy } from "@/lib/utils/groupBy";
import { formatDateTime } from "@/lib/utils/formatDate";
import { formatPrice } from "@/lib/utils/formatPrice";
import { ActionExt, Venue } from "@/lib/types";

import { EventItem } from "./EventItem";
import { Selector } from "./selector/Selector";

import styles from "./TimeTable.module.css";

type TimeTableProps = {
	data: ActionExt & {
		actionVenues: Venue[];
	};
};

export const TimeTable: React.FC<TimeTableProps> = ({ data }) => {
	const params = useParams();
	const navigate = useNavigate();
	const { city } = useActions();
	const [month, setMonth] = useState(0);
	const [day, setDay] = useState(0);

	const venuesItems = useMemo(() => {
		return data.actionVenues.map(({ venueId, venueName }) => ({
			id: String(venueId),
			label: venueName,
		}));
	}, [data.actionVenues]);

	const selectedVenue = useMemo(() => {
		const current = venuesItems.find(
			({ id }) => id === params.action?.split("_")[0],
		);

		if (current) {
			return current;
		}

		return null;
	}, [venuesItems, params.action]);

	const selectVenue = (id: string) => {
		navigate(`/city/${city.cityId}/${id}_${data.actionId}`, {
			replace: true,
		});
	};

	const events = useMemo(() => {
		return Object.entries(data.events).map(([key, event]) => ({
			...event,
			date: new Date(Number(key) * 1000),
		}));
	}, [data.events]);

	const monthsItems = useMemo(() => {
		const group = groupBy(events, ({ date }) => date.getMonth());

		return Object.keys(group).map((mo) => {
			const date = new Date();
			date.setDate(1);
			date.setMonth(Number(mo));

			return {
				id: String(date.getTime()),
				label: formatDateTime(date, { month: "long" }),
			};
		});
	}, [events]);

	const selectedMonth = useMemo(() => {
		const current = monthsItems.find(({ id }) => Number(id) === month);

		if (current) {
			return current;
		}

		return monthsItems[0];
	}, [monthsItems, month]);

	const days = useMemo(() => {
		const monthEvents = events.filter(
			({ date }) =>
				date.getMonth() === new Date(Number(selectedMonth.id)).getMonth(),
		);

		const group = groupBy(monthEvents, ({ date }) =>
			new Date(date.setHours(0, 0)).getTime(),
		);

		return Object.keys(group).map((d) => ({
			id: d,
			label: formatDateTime(new Date(Number(d)), {
				weekday: "short",
				day: "numeric",
			}),
		}));
	}, [events, selectedMonth]);

	const selectedDay = useMemo(() => {
		const current = days.find(({ id }) => Number(id) === day);

		if (current) {
			return current;
		}

		return days[0];
	}, [days, day]);

	const eventsForDay = useMemo(() => {
		return events.filter(
			({ date }) =>
				date.getMonth() === new Date(Number(selectedMonth.id)).getMonth() &&
				date.getDate() === new Date(Number(selectedDay.id)).getDate(),
		);
	}, [events, selectedMonth, selectedDay]);

	const isOneTimeEvent =
		monthsItems.length === 1 && days.length === 1 && eventsForDay.length === 1;

	return (
		<div className={styles["container"]}>
			<div className={styles["header"]}>
				<Typography.TitleMobile view="small" tag="h3" weight="bold">
					Расписание
				</Typography.TitleMobile>
			</div>

			<Space size="l" fullWidth>
				<Space size="s" fullWidth>
					{data.actionVenues.length > 1 && (
						<Selector
							title="Площадка"
							items={venuesItems}
							selectedItem={selectedVenue}
							onSelect={({ id }) => selectVenue(id)}
						/>
					)}

					{monthsItems.length > 1 && (
						<Selector
							title="Месяц"
							items={monthsItems}
							selectedItem={selectedMonth}
							onSelect={({ id }) => setMonth(Number(id))}
						/>
					)}

					{days.length > 1 && (
						<Selector
							title="День"
							items={days}
							selectedItem={selectedDay}
							onSelect={({ id }) => setDay(Number(id))}
						/>
					)}
				</Space>

				{isOneTimeEvent ? (
					<Space
						fullWidth
						size="l"
						className={styles["one-time-event-content"]}
					>
						<div className={styles["one-time-event-row"]}>
							<div>
								<Typography.Text>Дата</Typography.Text>
							</div>
							<Typography.Text weight="medium">
								{formatDateTime(new Date(data.from * 1000), {
									day: "numeric",
									month: "long",
									weekday: "short",
									hour: "2-digit",
									minute: "2-digit",
								})}
							</Typography.Text>
						</div>

						{data.minPrice && (
							<div className={styles["one-time-event-row"]}>
								<div>
									<Typography.Text>Цена</Typography.Text>
								</div>
								<Typography.Text view="primary-large" weight="bold">
									от {formatPrice({ currency: "RUB", amount: data.minPrice })}
								</Typography.Text>
							</div>
						)}

						<div className={styles["one-time-event-row"]}>
							<div className={styles["cashback"]}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width={24}
									height={24}
									fill="none"
								>
									<title>Alfa</title>
									<path
										fill="#EF3124"
										fillRule="evenodd"
										d="M22.5 12.002c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5ZM11.988 7.313l-1.3 3.75h2.576l-1.228-3.75h-.048Zm.096-2.061c1.044 0 1.348.604 1.613 1.37l2.733 7.911h-2.023l-.613-1.88h-3.66l-.662 1.88H7.57l2.868-7.912c.278-.767.602-1.37 1.646-1.37ZM7.57 16.642h8.86v2.11H7.57v-2.11Z"
										clipRule="evenodd"
									/>
								</svg>
								<Typography.Text view="primary-medium">
									Кэшбэк - {data.cashback}%
								</Typography.Text>
							</div>
							{data.minPrice && (
								<Typography.Text view="primary-medium" weight="medium">
									{`от ${formatPrice({
										amount: (data.minPrice / 100) * 15,
										currency: "RUB",
									})}`}
								</Typography.Text>
							)}
						</div>
					</Space>
				) : (
					<Space size={0} fullWidth divider={<Divider />}>
						{eventsForDay.map((data) => (
							<EventItem key={data.actionEventId} data={data} />
						))}
					</Space>
				)}
			</Space>
		</div>
	);
};
