import { useCallback, useState } from "react";
import { Input } from "@alfalab/core-components/input";
import { Checkbox } from "@alfalab/core-components/checkbox";
import { CheckboxGroup } from "@alfalab/core-components/checkbox-group";
import { Typography } from "@alfalab/core-components/typography";

import { Genre } from "@/lib/types";

import styles from "./GenresFilter.module.css";

type GenresFilterProps = {
	cityGenres: Array<Genre>;
	selectedGenres: Record<number, boolean>;
	onSelectGenres(genres: Record<number, boolean>): void;
};

export const GenresFilter: React.FC<GenresFilterProps> = ({
	cityGenres,
	selectedGenres,
	onSelectGenres,
}) => {
	const [query, setQuery] = useState("");

	const handleChange = useCallback(
		(payload: { name: string; checked: boolean }) => {
			const genresToUpdate = {
				...selectedGenres,
				[Number(payload.name)]: payload.checked,
			};

			if (!payload.checked) {
				delete genresToUpdate[Number(payload.name)];
			}

			onSelectGenres(genresToUpdate);
		},
		[onSelectGenres, selectedGenres],
	);

	return (
		<div>
			<Input
				block
				placeholder="Название"
				size="s"
				clear={true}
				value={query}
				onChange={(_, { value }) => setQuery(value)}
				onClear={() => setQuery("")}
			/>

			<div className={styles["list"]}>
				{cityGenres.length === 0 ? (
					<Typography.Text>Нет жанров</Typography.Text>
				) : (
					<CheckboxGroup
						onChange={(_, { name, checked }) => {
							if (name) {
								handleChange({ name, checked });
							}
						}}
					>
						{cityGenres.map(({ genreId, genreName }) => (
							<Checkbox
								key={genreId}
								size="m"
								label={genreName}
								name={genreId.toString()}
								checked={selectedGenres[genreId] ?? false}
							/>
						))}
					</CheckboxGroup>
				)}
			</div>
		</div>
	);
};
