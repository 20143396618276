import { Suspense } from "react";
import {
	Outlet,
	Await,
	createBrowserRouter,
	defer,
	LoaderFunction,
	RouterProvider,
	useAsyncError,
	useLoaderData,
	Navigate,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { MainLayout } from "@/components/layouts/main-layout/MainLayout";
import { PageLoader } from "@/components/page-loader/PageLoader";
import { ErrorBoundary } from "@/components/error/ErrorBoundary";
import { OnboardingView } from "@/views/onboarding/OnboardingView";
import { AuthView, authLoader } from "@/views/auth/AuthView";
import { CityView } from "@/views/city/CityView";
import {
	CitiesView,
	citiesAction,
	citiesLoader,
} from "@/views/cities/CitiesView";
import { ActionView, actionLoader } from "@/views/action/ActionView";
import { FiltersView } from "@/views/filters/FiltersView";
import { FiltersCityView } from "@/views/filters/city/CityView";
import { FiltersGenresView } from "@/views/filters/genres/GenresView";
import { FiltersKindView } from "@/views/filters/kind/KindView";
import { FiltersVenuesView } from "@/views/filters/venues/VenuesView";
import { FiltersDateView } from "@/views/filters/date/DateView";
import {
	ReservationView,
	reservationAction,
} from "@/views/reservation/ReservationView";
import {
	CheckoutView,
	checkoutAction,
	checkoutLoader,
	CheckoutViewError,
} from "@/views/checkout/CheckoutView";
import { TicketView, ticketLoader } from "@/views/ticket/TicketView";
import { TicketsView } from "@/views/tickets/TicketsView";
import {
	OrdersView,
	ordersLoader,
	ordersAction,
} from "@/views/orders/OrdersView";
import { AccountView } from "@/views/account/AccountView";
import { SettingsView } from "@/views/settings/SettingsView";
import {
	SuccessPaymentView,
	successPaymentLoader,
} from "@/views/payment/SuccessPaymentView";
import { FailedPaymentView, failedPaymentLoader } from "@/views/payment/FailedPaymentView";
import { WithCart } from "@/lib/cart/CartContext";
import { WithActions } from "@/lib/actions/ActionsContext";
import { WithTheme } from "@/lib/theme";
import { apiService } from "@/lib/api";
import { APIError } from "@/lib/error";
import { GetAllActionsByCityResult } from "@/lib/types";

type ActionsData = {
	actions: Promise<GetAllActionsByCityResult>;
};

const RootWrapper: React.FC = () => {
	return (
		<QueryParamProvider adapter={ReactRouter6Adapter}>
			<WithCart>
				<MainLayout />
			</WithCart>
		</QueryParamProvider>
	);
};

const CityWrapperError: React.FC = () => {
	const error = useAsyncError() as Error;

	if (error instanceof APIError && error.type === "message") {
		return Navigate({ to: "/city", replace: true });
	}

	throw error;
};

const CityWrapper = () => {
	const data = useLoaderData() as ActionsData;

	return (
		<Suspense fallback={<PageLoader />}>
			<Await resolve={data.actions} errorElement={<CityWrapperError />}>
				{(actions) => (
					<WithActions data={actions}>
						<Outlet />
					</WithActions>
				)}
			</Await>
		</Suspense>
	);
};

const cityLoader: LoaderFunction = ({ params }) => {
	const cityId = params.cid;
	const actionsPromise = apiService.getAllActionsByCity({
		cid: String(cityId),
	});

	return defer({
		actions: actionsPromise,
	});
};

const router = createBrowserRouter([
	{
		element: <RootWrapper />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: "/",
				element: <OnboardingView pageId={1} />,
			},
			// Auth callback page
			{
				path: "/alfa-auth/:guid",
				element: <AuthView pageId={1} />,
				loader: authLoader,
			},
			// Set city page
			{
				path: "/city",
				element: <CitiesView pageId={1} />,
				loader: citiesLoader,
				action: citiesAction,
			},
			{
				element: <CityWrapper />,
				loader: cityLoader,
				shouldRevalidate: () => false,
				children: [
					// Action pages
					{
						path: "/city/:cid",
						element: <CityView pageId={1} />,
					},
					// Filters
					{
						path: "/city/:cid/filters",
						element: <FiltersView pageId={4} />,
					},
					{
						path: "/city/:cid/filters/city",
						element: <FiltersCityView pageId={5} />,
					},
					{
						path: "/city/:cid/filters/genres",
						element: <FiltersGenresView pageId={5} />,
					},
					{
						path: "/city/:cid/filters/kind",
						element: <FiltersKindView pageId={5} />,
					},
					{
						path: "/city/:cid/filters/venues",
						element: <FiltersVenuesView pageId={5} />,
					},
					{
						path: "/city/:cid/filters/date",
						element: <FiltersDateView pageId={5} />,
					},
					{
						id: "action",
						loader: actionLoader,
						shouldRevalidate: () => false,
						children: [
							{
								path: "/city/:cid/:action",
								element: <ActionView pageId={2} />,
							},
							{
								path: "/city/:cid/:action/reservation/:eventId",
								element: <ReservationView pageId={3} />,
								action: reservationAction,
							},
						],
					},
				],
			},
			// Checkout page (create order)
			{
				path: "/checkout",
				element: <CheckoutView pageId={4} />,
				loader: checkoutLoader,
				action: checkoutAction,
				errorElement: <CheckoutViewError />,
			},
			// Account page
			{
				path: "/account",
				element: <AccountView pageId={2} />,
			},
			{
				path: "/settings",
				element: <SettingsView pageId={3} />,
			},
			{
				path: "/tickets",
				element: <TicketsView pageId={3} />,
			},
			// My orders page
			{
				path: "/orders",
				element: <OrdersView pageId={3} />,
				loader: ordersLoader,
				action: ordersAction,
			},
			// Ticket page
			{
				path: "/orders/:oid",
				element: <TicketView pageId={4} />,
				loader: ticketLoader,
			},
			// Success payment callback page
			{
				path: "/success/:oid/:api_key",
				element: <SuccessPaymentView pageId={3} />,
				loader: successPaymentLoader,
			},
			// Failed payment callback page
			{
				path: "/fail/:oid/:api_key",
				element: <FailedPaymentView pageId={3} />,
				loader: failedPaymentLoader,
			},
		],
	},
]);

export const App: React.FC = () => {
	return (
		<WithTheme>
			<RouterProvider router={router} />
		</WithTheme>
	);
};
