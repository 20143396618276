import { Link } from "react-router-dom";
import { Space } from "@alfalab/core-components/space";
import { PureCell } from "@alfalab/core-components/pure-cell";
import { Switch } from "@alfalab/core-components/switch";
import { Divider } from "@alfalab/core-components/divider";
import { ChevronRightMIcon } from "@alfalab/icons-glyph/ChevronRightMIcon";

import { usePageSettings } from "@/lib/hooks";
import { useTheme } from "@/lib/theme";

import styles from "./SettingsView.module.css";

type MenuItemProps = {
	title: string;
};

const MenuItem: React.FC<MenuItemProps> = ({ title }) => {
	return (
		<PureCell direction="horizontal" verticalPadding="compact">
			<PureCell.Content>
				<PureCell.Main>
					<PureCell.Text
						titleColor="primary"
						valueColor="secondary"
						view="component"
					>
						{title}
					</PureCell.Text>
				</PureCell.Main>
			</PureCell.Content>
			<PureCell.Addon verticalAlign="center">
				<ChevronRightMIcon fill="rgba(116, 116, 116, 1)" />
			</PureCell.Addon>
		</PureCell>
	);
};

type SettingsViewProps = {
	pageId: number;
};

export const SettingsView: React.FC<SettingsViewProps> = ({ pageId }) => {
	const { theme, toggleTheme } = useTheme();

	usePageSettings({
		pageId,
		pageTitle: "Настройки",
	});

	return (
		<div className={styles["container"]}>
			<Space size="l" fullWidth divider={<Divider />}>
				<PureCell direction="horizontal" verticalPadding="compact">
					<PureCell.Content>
						<PureCell.Main>
							<Switch
								block={true}
								reversed={true}
								checked={theme === "dark"}
								label="Переключить на тёмную тему"
								onChange={() => toggleTheme()}
							/>
						</PureCell.Main>
					</PureCell.Content>
				</PureCell>

				<Link to="/city">
					<MenuItem title="Город" />
				</Link>
				{/* <MenuItem title="Документация" /> */}
			</Space>
		</div>
	);
};
