import { Suspense, useEffect, useState } from "react";
import {
	ActionFunction,
	Await,
	Form,
	LoaderFunction,
	defer,
	useActionData,
	useLoaderData,
	useNavigate,
	useNavigation,
} from "react-router-dom";
import { RadioGroup } from "@alfalab/core-components/radio-group";
import { Radio } from "@alfalab/core-components/radio";
import { Button } from "@alfalab/core-components/button";
import { Skeleton } from "@alfalab/core-components/skeleton";
import { Space } from "@alfalab/core-components/space";

import { PageError } from "@/components/error/PageError";
import { usePageSettings } from "@/lib/hooks/usePageSettings";
import { apiService } from "@/lib/api";
import { GetAllCitiesResult, SetCityResult, RouteAction } from "@/lib/types";

import styles from "./CitiesView.module.css";

type CitiesData = {
	allCities: GetAllCitiesResult;
};

type CitiesAction = RouteAction<SetCityResult>;

const CitiesViewError: React.FC = () => {
	const navigate = useNavigate();

	return (
		<PageError
			action={{
				label: "Продолжить",
				callback: () => {
					navigate(window.location.pathname, { replace: true });
				},
			}}
		/>
	);
};

type CitiesViewProps = {
	pageId: number;
};

export const CitiesView: React.FC<CitiesViewProps> = ({ pageId }) => {
	const navigate = useNavigate();
	const { state } = useNavigation();
	const data = useLoaderData() as CitiesData;
	const action = useActionData() as CitiesAction;
	const [selectedCityId, setSelectedCityId] = useState<string>();

	usePageSettings({
		pageId,
		pageTitle: "Укажите город",
	});

	useEffect(() => {
		if (action?.data === "Ok") {
			navigate(`/city/${selectedCityId}`, { replace: true });
		}
	}, [action]);

	return (
		<div className={styles["container"]}>
			<Suspense
				fallback={
					<Space size="m">
						{[...Array(4)].map((_, idx) => (
							<Skeleton key={String(idx)} visible>
								<span
									style={{
										display: "inline-block",
										height: "16px",
										width: `${120 + 16 * idx}px`,
									}}
								/>
							</Skeleton>
						))}
					</Space>
				}
			>
				<Await resolve={data.allCities} errorElement={<CitiesViewError />}>
					{(countries: GetAllCitiesResult) => (
						<Form method="post" action="/city" className={styles["form"]}>
							{countries.length > 0 && (
								<RadioGroup
									name="cityId"
									onChange={(_, { value }) => setSelectedCityId(value)}
									value={selectedCityId}
								>
									{countries[0].cities.map(({ cityId, cityName }) => (
										<Radio
											key={cityId}
											size="m"
											label={cityName}
											value={cityId}
										/>
									))}
								</RadioGroup>
							)}

							{selectedCityId && (
								<Button
									type="submit"
									view="primary"
									size="s"
									loading={state === "submitting"}
									className={styles["action"]}
								>
									Продолжить
								</Button>
							)}
						</Form>
					)}
				</Await>
			</Suspense>
		</div>
	);
};

export const citiesLoader: LoaderFunction = () => {
	const allCitiesPromise = apiService.getAllCities();

	return defer({
		allCities: allCitiesPromise,
	});
};

export const citiesAction: ActionFunction = async ({ request }) => {
	const formData = await request.formData();
	const cityId = formData.get("cityId");

	if (cityId) {
		return apiService.setCity({ cityId: cityId.toString() }).then((data) => ({
			data,
		}));
	}
};
