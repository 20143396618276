import { Fragment, Suspense } from "react";
import {
	Await,
	LoaderFunction,
	useFetcher,
	useLoaderData,
	useNavigate,
} from "react-router-dom";
import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { Space } from "@alfalab/core-components/space";
import { Divider } from "@alfalab/core-components/divider";
import { BottomSheet } from "@alfalab/core-components/bottom-sheet";

import { PageError } from "@/components/error/PageError";
import { usePageSettings } from "@/lib/hooks/usePageSettings";
import { useToggle } from "@/lib/hooks";
import { apiService } from "@/lib/api";
import { GetTicketsByOrderResult } from "@/lib/types";

import { EventHeader } from "./event-header/EventHeader";
import { Details } from "./details/Details";
import { Summary } from "./summary/Summary";

import styles from "./TicketView.module.css";

type Ticket = GetTicketsByOrderResult & {
	action: {
		actionName: string;
		poster: string;
		date: Date;
	};
};

type TicketData = {
	ticket: Promise<Ticket>;
};

type TicketProps = {
	data?: Ticket;
};

const Tickets: React.FC<TicketProps> = ({ data }) => {
	const [isOpenReturnModal, toggleOpenReturnModal] = useToggle(false);

	return (
		<Fragment>
			<Space fullWidth className={styles["container"]}>
				<EventHeader data={data?.action} />

				{data && (
					<Space fullWidth size="l">
						<Details
							data={{
								date: data.action.date,
								orderNo: data.orderId,
							}}
						/>

						<Space fullWidth divider={<Divider />}>
							{data?.ticketList.map((ticket) => (
								<Summary key={ticket.actionEventId} data={ticket} />
							))}
						</Space>

						<Button
							block
							size="s"
							view="primary"
							onClick={() => toggleOpenReturnModal(true)}
						>
							Вернуть билеты
						</Button>
					</Space>
				)}
			</Space>

			<BottomSheet
				hasCloser
				open={isOpenReturnModal}
				onClose={() => toggleOpenReturnModal(false)}
				actionButton={
					<Space size="s" fullWidth>
						<Button block size="s" view="primary" onClick={() => {}}>
							Вернуть билеты
						</Button>
						<Button
							block
							size="s"
							view="secondary"
							onClick={() => toggleOpenReturnModal(false)}
						>
							Назад
						</Button>
					</Space>
				}
				stickyHeader
				usePortal
			>
				<Typography.Text view="primary-small">
					Ознакомьтесь с правилами возврата и загрузите требуемые документы.
					Правила доступны по ссылке
				</Typography.Text>
			</BottomSheet>
		</Fragment>
	);
};

const TicketPageError: React.FC = () => {
	const fetcher = useFetcher();

	return (
		<PageError
			action={{
				label: "Продолжить",
				callback: () => fetcher.load(location.pathname),
			}}
		/>
	);
};

type TicketViewProps = {
	pageId: number;
};

export const TicketView: React.FC<TicketViewProps> = ({ pageId }) => {
	const data = useLoaderData() as TicketData;

	usePageSettings({
		pageId,
		pageTitle: "Мои билеты",
	});

	return (
		<Suspense fallback={<Tickets />}>
			<Await resolve={data.ticket} errorElement={<TicketPageError />}>
				{(data) => <Tickets data={data} />}
			</Await>
		</Suspense>
	);
};

function getDate(dateString: string) {
	const [date, time] = dateString.split(" ");
	const [day, mounth, year] = date.split(".");
	const [hours, minutes] = time.split(":");

	return new Date(`${year}-${mounth}-${day}T${hours}:${minutes}`);
}

export const ticketLoader: LoaderFunction = ({ params }) => {
	const ticketPromise = apiService
		.getTicketsByOrder({
			oid: Number(params.oid),
		})
		.then((data) => {
			const ticket = data.ticketList[0];

			return {
				...data,
				action: {
					actionName: ticket.actionName,
					poster: ticket.smallPosterUrl,
					date: getDate(ticket.date),
				},
			};
		});

	return {
		ticket: ticketPromise,
	};
};
