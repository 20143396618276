import React, {
	Suspense,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import {
	Form,
	useLoaderData,
	useActionData,
	useNavigation,
	LoaderFunction,
	ActionFunction,
	defer,
	Await,
	useNavigate,
	useSubmit,
	redirect,
} from "react-router-dom";
import { Typography } from "@alfalab/core-components/typography";
import { Input } from "@alfalab/core-components/input";
import { Button } from "@alfalab/core-components/button";
import { Space } from "@alfalab/core-components/space";
import { BottomSheet } from "@alfalab/core-components/bottom-sheet";

import { PageLoader } from "@/components/page-loader/PageLoader";
import { PageError } from "@/components/error/PageError";
import { useCart } from "@/lib/cart/CartContext";
import { usePageSettings, useToggle } from "@/lib/hooks";
import { formatPrice } from "@/lib/utils/formatPrice";
import { apiService } from "@/lib/api";
import { GetCartResult, CartSeat } from "@/lib/types";

import { Summary } from "./summary/Summary";

import styles from "./CheckoutView.module.css";

function formatTimer(timer: number) {
	return new Intl.DateTimeFormat("ru-RU", {
		minute: "2-digit",
		second: "2-digit",
	}).format(new Date(timer));
}

const NAME_REGEX =
	/^[А-ЯЁ][а-яё]*([-][А-ЯЁ][а-яё]*)?\s[А-ЯЁ][а-яё]*(\s[А-ЯЁ][а-яё]*)?$/i;

const TEL_REGEX = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

function validateName(value: string) {
	if (value === "") {
		return "Это поле обязательно";
	} else if (!NAME_REGEX.test(value)) {
		return "Некорректное ФИО";
	} else {
		return null;
	}
}

function validatePhone(value: string) {
	if (value === "") {
		return "Это поле обязательно";
	} else if (!TEL_REGEX.test(value)) {
		return "Некорректный номер";
	} else {
		return null;
	}
}

type Cart = GetCartResult & {
	event: {
		name: string;
		poster: string;
		address: string;
		date: Date;
		seatList: CartSeat[];
		fullNameRequired: boolean;
		phoneRequired: boolean;
		fanIdRequired: boolean;
	};
};

type CheckoutData = {
	cart: Promise<Cart | null>;
};

type AdditionalInfoModalProps = {
	open: boolean;
	onClose(): void;
	fullNameRequired: boolean;
	phoneRequired: boolean;
	totalSum: number;
	currency: string;
	submitting: boolean;
};

const AdditionalInfoModal: React.FC<AdditionalInfoModalProps> = ({
	fullNameRequired,
	phoneRequired,
	totalSum,
	currency,
	open,
	onClose,
	submitting,
}) => {
	const submit = useSubmit();
	const [name, setName] = useState<string>("");
	const [phone, setPhone] = useState<string>("");
	const [nameError, setNameError] = useState<string | null>(null);
	const [phoneError, setPhoneError] = useState<string | null>(null);
	const dirtyRef = useRef(false);

	const handleChangeName = (value: string) => {
		dirtyRef.current && setNameError(validateName(value));
		setName(value);
	};

	const handleChangePhone = (value: string) => {
		dirtyRef.current && setPhoneError(validatePhone(value));
		setPhone(value);
	};

	const handleSubmit = () => {
		dirtyRef.current = true;
		const nameErr = validateName(name);
		const phoneErr = validatePhone(phone);

		setNameError(nameErr);
		setPhoneError(phoneErr);

		if (!nameErr && !phoneErr) {
			const formData = new FormData();
			formData.append("sum", totalSum.toString());
			formData.append("currency", currency);
			formData.append("phone", phone);
			formData.append("name", name);

			submit(formData, { method: "post", action: "/checkout" });
		}
	};

	return (
		<BottomSheet open={open} onClose={onClose} stickyHeader usePortal>
			<Space fullWidth>
				{fullNameRequired && (
					<Input
						required
						block
						name="name"
						label="ФИО"
						placeholder="Иванов Иван Иванович"
						size="m"
						onChange={(_, { value }) => handleChangeName(value)}
						error={nameError}
					/>
				)}
				{phoneRequired && (
					<Input
						required
						type="tel"
						block
						name="phone"
						label="Телефон"
						placeholder="+7"
						size="m"
						onChange={(_, { value }) => handleChangePhone(value)}
						error={phoneError}
					/>
				)}

				<Button
					type="button"
					block
					size="s"
					view="primary"
					loading={submitting}
					onClick={handleSubmit}
				>
					Продолжить
				</Button>
			</Space>
		</BottomSheet>
	);
};

type CheckoutProps = {
	data?: Cart | null;
};

const Checkout: React.FC<CheckoutProps> = ({ data }) => {
	const navigate = useNavigate();
	const { state } = useNavigation();
	const actionData = useActionData() as { formUrl: string } | undefined;
	const submit = useSubmit();
	const localCart = useCart();
	const [showAdditionalInfoModal, toggleAdditionalInfoModal] = useToggle(false);
	const [isCanceledCart, toggleCanceledCart] = useToggle(false);
	const [timer, setSeconds] = useState<number>(data?.time || -1);
	const intervalRef = useRef<NodeJS.Timer>();

	const clearCart = () => {
		clearInterval(intervalRef.current);
		apiService.unreserveAll().then(() => {
			localCart.removeItems();
			navigate(window.location.pathname, { replace: true });
		});
	};

	// useEffect(() => {
	// 	if (actionData?.formUrl) {
	// 		localCart.removeItems();
	// 		window.location.href = actionData.formUrl;
	// 	}
	// }, [actionData]);

	// Timer
	useEffect(() => {
		if (timer !== -1) {
			intervalRef.current = setInterval(() => {
				if (timer > 0) {
					setSeconds(timer - 1);
				} else {
					toggleCanceledCart(true);
					clearCart();
				}
			}, 1000);

			return () => {
				clearInterval(intervalRef.current);
			};
		}
	});

	if (data === undefined) {
		return null;
	}

	if (isCanceledCart) {
		return (
			<div className={styles["centered-container"]}>
				<Typography.Text view="primary-medium">
					Время ожидания истекло
				</Typography.Text>
			</div>
		);
	}

	if (data === null) {
		return (
			<div className={styles["centered-container"]}>
				<Typography.Text view="primary-medium">
					Ваша корзина пуста
				</Typography.Text>
			</div>
		);
	}

	return (
		<div className={styles["container"]}>
			<Space size="l" fullWidth>
				<div className={styles["header"]}>
					{/* <Typography.Text view="primary-large" weight="bold">
						Корзина
					</Typography.Text> */}

					<Typography.Text view="primary-small" weight="bold" color="accent">
						{formatTimer(timer * 1000)}
					</Typography.Text>
				</div>

				<Space size="s" fullWidth>
					<Summary data={data} />

					<Button
						size="s"
						view="secondary"
						block
						// loading={isLoading}
						onClick={clearCart}
						className={styles["action"]}
					>
						Удалить всё
					</Button>
				</Space>

				<Space size="m" fullWidth>
					<Typography.TitleMobile tag="h2" view="small" weight="bold">
						Оплата
					</Typography.TitleMobile>

					<Space size="s" fullWidth>
						<div className={styles["row"]}>
							<Typography.Text view="primary-medium">
								Сумма заказа
							</Typography.Text>

							<Typography.Text view="primary-medium">
								{formatPrice({
									amount: data.totalSum,
									currency: data.currency,
								})}
							</Typography.Text>
						</div>

						<div className={styles["row"]}>
							<Typography.Text view="primary-medium">
								Сервисный сбор
							</Typography.Text>

							<Typography.Text view="primary-medium">
								{formatPrice({
									amount: data.totalServiceCharge,
									currency: data.currency,
								})}
							</Typography.Text>
						</div>
					</Space>

					<div className={styles["row"]}>
						<Typography.Text view="primary-medium">К оплате</Typography.Text>

						<Typography.Text view="primary-large" weight="bold">
							{formatPrice({
								amount: data.totalSum,
								currency: data.currency,
							})}
						</Typography.Text>
					</div>

					<div className={styles["cashback-container"]}>
						<div className={styles["cashback"]}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								fill="none"
							>
								<title>Alfa</title>
								<path
									fill="#EF3124"
									fillRule="evenodd"
									d="M22.5 12.002c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5ZM11.988 7.313l-1.3 3.75h2.576l-1.228-3.75h-.048Zm.096-2.061c1.044 0 1.348.604 1.613 1.37l2.733 7.911h-2.023l-.613-1.88h-3.66l-.662 1.88H7.57l2.868-7.912c.278-.767.602-1.37 1.646-1.37ZM7.57 16.642h8.86v2.11H7.57v-2.11Z"
									clipRule="evenodd"
								/>
							</svg>

							<Typography.Text view="primary-small">Кэшбэк</Typography.Text>
						</div>

						<Typography.Text view="primary-medium">
							{formatPrice({
								amount: (data.totalSum / 100) * 15,
								currency: data.currency,
							})}
						</Typography.Text>
					</div>
				</Space>
			</Space>

			{data.event.fullNameRequired || data.event.phoneRequired ? (
				<Button
					type="button"
					size="m"
					view="accent"
					block
					onClick={() => toggleAdditionalInfoModal(true)}
				>
					Оплатить
				</Button>
			) : (
				<Button
					type="button"
					size="m"
					view="primary"
					block
					loading={state === "submitting"}
					onClick={() => {
						const formData = new FormData();
						formData.append("sum", data.totalSum.toString());
						formData.append("currency", data.currency.toString());

						submit(formData, { method: "post", action: "/checkout" });
					}}
				>
					Оплатить
				</Button>
			)}

			<Typography.Text
				tag="p"
				view="secondary-small"
				className={styles["policy-message"]}
			>
				Нажимая «Оплатить» вы соглашаетесь с условиями договора-оферты
			</Typography.Text>

			<AdditionalInfoModal
				open={showAdditionalInfoModal}
				onClose={() => toggleAdditionalInfoModal(false)}
				fullNameRequired={data.event.fullNameRequired}
				phoneRequired={data.event.phoneRequired}
				totalSum={data.totalSum}
				currency={data.currency}
				submitting={state === "submitting"}
			/>
		</div>
	);
};

export const CheckoutViewError: React.FC = () => {
	const navigate = useNavigate();

	return (
		<PageError
			action={{
				label: "Вернуться",
				callback: () => navigate(-1),
			}}
		/>
	);
};

type CheckoutViewProps = {
	pageId: number;
};

export const CheckoutView: React.FC<CheckoutViewProps> = ({ pageId }) => {
	const data = useLoaderData() as CheckoutData;

	usePageSettings({
		pageId,
		pageTitle: "Подтверждение заказа",
	});

	return (
		<Suspense fallback={<PageLoader />}>
			<Await resolve={data.cart} errorElement={<CheckoutViewError />}>
				{(cart) => <Checkout data={cart} />}
			</Await>
		</Suspense>
	);
};

export const checkoutLoader: LoaderFunction = () => {
	const cartPromise = apiService.getCart().then((cart) => {
		if (cart.actionEventList.length > 0) {
			const actionEvent = cart.actionEventList[0];

			const [day, mounth, year] = actionEvent.day.split(".");
			const [hours, minutes] = actionEvent.time.split(":");

			const eventDate = new Date(
				`${year}-${mounth}-${day}T${hours}:${minutes}`,
			);

			return {
				...cart,
				event: {
					name: actionEvent.actionName,
					poster: actionEvent.smallPosterUrl,
					address: actionEvent.venueName,
					date: eventDate,
					seatList: actionEvent.seatList,
					fullNameRequired: actionEvent.fullNameRequired,
					phoneRequired: actionEvent.phoneRequired,
					fanIdRequired: actionEvent.fanIdRequired,
				},
			};
		} else {
			return null;
		}
	});

	return defer({
		cart: cartPromise,
	});
};

export const checkoutAction: ActionFunction = async ({ request }) => {
	const formData = await request.formData();
	const sum = formData.get("sum");
	const currency = formData.get("currency");
	const name = formData.get("name");
	const phone = formData.get("phone");

	return apiService
		.createOrder({
			sum: String(sum),
			currency: String(currency),
			name: name?.toString(),
			phone: phone?.toString(),
		})
		.then(({ formUrl }) => {
			return redirect(formUrl);
		});
};
