import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";
import { Typography } from "@alfalab/core-components/typography";
import cx from "classnames";
import { ArrowLeftMIcon } from "@alfalab/icons-glyph/ArrowLeftMIcon";
import { ArrowRightMIcon } from "@alfalab/icons-glyph/ArrowRightMIcon";

import "swiper/css";

import { Cashback } from "@/components/cashback/Cashback";
import { formatString } from "@/lib/utils/formatString";
import { SliderItem } from "@/lib/types";

import styles from "./Slider.module.css";

// type SliderButtonProps = {
// 	active: boolean;
// 	onClick(): void;
// };

// const SliderButton: React.FC<SliderButtonProps> = ({ active, onClick }) => {
// 	const [timer, setTimer] = useState(0);
// 	const intervalRef = useRef<NodeJS.Timer>();

// 	useEffect(() => {
// 		const fr = 1000 / 60;

// 		if (active) {
// 			intervalRef.current = setInterval(() => {
// 				setTimer((prev) => {
// 					const nextValue = prev + fr;

// 					if (nextValue === 7000) {
// 						clearInterval(intervalRef.current);

// 						return 0;
// 					} else {
// 						return nextValue;
// 					}
// 				});
// 			}, fr);

// 			return () => {
// 				clearInterval(intervalRef.current);
// 			};
// 		} else {
// 			setTimer(0);
// 		}
// 	}, [active]);

// 	return (
// 		<button
// 			type="button"
// 			className={cx(styles["control"], active && styles["control-active"])}
// 			onClick={onClick}
// 		>
// 			<div className={styles["control-inner"]}>
// 				<span
// 					className={styles["control-indicator"]}
// 					style={active ? { width: `${timer / (7000 / 100)}%` } : {}}
// 				/>
// 			</div>
// 		</button>
// 	);
// };

type VideoProps = React.DetailedHTMLProps<
	React.VideoHTMLAttributes<HTMLVideoElement>,
	HTMLVideoElement
> & {
	active: boolean;
};

const Video: React.FC<VideoProps> = ({ src, active, ...props }) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);

	useEffect(() => {
		if (videoRef.current) {
			if (active) {
				videoRef.current.play();
			} else {
				videoRef.current.pause();
				videoRef.current.currentTime = 0;
			}
		}
	}, [active]);

	return <video ref={videoRef} src={src} autoPlay muted loop {...props} />;
};

type SliderProps = {
	data: Array<SliderItem>;
};

export const Slider: React.FC<SliderProps> = ({ data }) => {
	const navigate = useNavigate();
	const [activeIndex, setActiveIndex] = useState(0);
	const swiperRef = useRef<SwiperRef | null>(null);
	const [timer, setTimer] = useState(0);
	const intervalRef = useRef<NodeJS.Timer>();

	useEffect(() => {
		const fr = 1000 / 60;

		setTimer(0);

		intervalRef.current = setInterval(() => {
			setTimer((prev) => {
				const nextValue = prev + fr;

				if (nextValue === 7000) {
					clearInterval(intervalRef.current);

					return 0;
				} else {
					return nextValue;
				}
			});
		}, fr);

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [activeIndex]);

	if (data.length === 0) {
		return null;
	}

	return (
		<div className={styles["container"]}>
			<Swiper
				ref={swiperRef}
				spaceBetween={0}
				slidesPerView={1}
				loop
				navigation={{
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				}}
				autoplay={{
					delay: 7000,
					pauseOnMouseEnter: false,
					disableOnInteraction: false,
				}}
				modules={[Autoplay, Navigation]}
				onSlideChange={({ realIndex }) => setActiveIndex(realIndex)}
			>
				{data.map((slide, idx) => (
					<SwiperSlide
						key={slide.id}
						onClick={() =>
							navigate(
								slide.eventId
									? `/city/${slide.cityId}/${slide.venueId}_${slide.actionId}/reservation/${slide.eventId}`
									: `/city/${slide.cityId}/${slide.venueId}_${slide.actionId}`,
							)
						}
					>
						<div className={styles["slide"]}>
							{slide.video ? (
								<Video
									src={slide.video}
									active={idx === activeIndex}
									className={styles["slide-content"]}
								/>
							) : (
								<img
									src={slide.picture}
									alt={slide.title}
									className={styles["slide-content"]}
								/>
							)}
						</div>

						<div className={styles["content"]}>
							<div className={styles["content-left"]}>
								<Typography.Text weight="bold">
									{formatString(slide.title) || "title"}
								</Typography.Text>
								<Typography.Text view="primary-small">
									{slide.dates}
								</Typography.Text>
								<Typography.Text view="primary-small" color="secondary">
									{formatString(slide.place) || "place"}
								</Typography.Text>
							</div>

							<Cashback value={`${slide.cashback}%`} />
						</div>
					</SwiperSlide>
				))}

				{/* {data.length > 1 && (
					<div className={styles["controls"]}>
						{data.map((slide, idx) => (
							<SliderButton
								key={slide.id}
								active={idx === activeIndex}
								onClick={() => swiperRef.current?.swiper.slideTo(idx)}
							/>
						))}
					</div>
				)} */}

				<div className={styles["controls"]}>
					<Typography.Text color="static-primary-light">
						{activeIndex + 1} / {data.length}
					</Typography.Text>

					<div className={styles["controls-arrows"]}>
						<button
							type="button"
							className={cx(styles["control-arrow"], "swiper-button-prev")}
						>
							<ArrowLeftMIcon style={{ zIndex: 1 }} />
						</button>

						<button
							type="button"
							className={cx(styles["control-arrow"], "swiper-button-next")}
							style={{
								background: `conic-gradient(red ${
									timer / (7000 / 100)
								}%, transparent 0)`,
							}}
						>
							<ArrowRightMIcon style={{ zIndex: 1 }} />
						</button>
					</div>
				</div>
			</Swiper>
		</div>
	);
};
