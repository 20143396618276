import { Typography } from "@alfalab/core-components/typography";

import { AgeLabel } from "@/components/age-label/AgeLabel";
import { Thumb } from "@/components/thumb/Thumb";
import { ActionExt } from "@/lib/types";

import { formatString } from "@/lib/utils/formatString";

import styles from "./EventHeader.module.css";

type EventHeaderProps = {
	data?: ActionExt;
};

export const EventHeader: React.FC<EventHeaderProps> = ({ data }) => {
	return (
		<div className={styles["container"]}>
			<Thumb
				src={data?.poster}
				alt={data?.actionName}
				style={{ aspectRatio: "64 / 67" }}
			/>

			<div className={styles["content"]}>
				{data && (
					<div className={styles["cashback"]}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							fill="none"
						>
							<title>Alfa</title>
							<path
								fill="#EF3124"
								fillRule="evenodd"
								d="M22.5 12.002c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5ZM11.988 7.313l-1.3 3.75h2.576l-1.228-3.75h-.048Zm.096-2.061c1.044 0 1.348.604 1.613 1.37l2.733 7.911h-2.023l-.613-1.88h-3.66l-.662 1.88H7.57l2.868-7.912c.278-.767.602-1.37 1.646-1.37ZM7.57 16.642h8.86v2.11H7.57v-2.11Z"
								clipRule="evenodd"
							/>
						</svg>
						<Typography.Text
							view="primary-medium"
							weight="medium"
							color="static-primary-dark"
						>
							{data.cashback}%
						</Typography.Text>
					</div>
				)}

				<Typography.TitleMobile
					view="small"
					tag="h2"
					weight="bold"
					color="static-primary-light"
					className={styles["subtitle"]}
				>
					{data && formatString(data.fullActionName)}
				</Typography.TitleMobile>

				<Typography.Text
					view="secondary-large"
					color="static-primary-light"
					className={styles["title"]}
				>
					{data && formatString(data.actionName)}
				</Typography.Text>
			</div>

			{data?.age && <AgeLabel age={data.age} className={styles["age"]} />}
		</div>
	);
};
