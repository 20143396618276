import { useNavigate, useRouteError } from "react-router-dom";
import { Button } from "@alfalab/core-components/button";

import { CriticalError } from "./CriticalError";

export const ErrorBoundary: React.FC = () => {
	const navigate = useNavigate();
	const error = useRouteError() as Error;

	console.error(error);

	return (
		<CriticalError
			action={
				<Button
					block
					size="m"
					view="primary"
					onClick={() => navigate("/", { replace: true })}
				>
					Вернуться в Альфа
				</Button>
			}
		/>
	);
};
