import { Fragment, useEffect, useRef, useState } from "react";
import {
	useActionData,
	useNavigate,
	useNavigation,
	useSubmit,
} from "react-router-dom";
import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { Input } from "@alfalab/core-components/input";
import { BottomSheet } from "@alfalab/core-components/bottom-sheet";

import { NonCriticalError } from "@/components/error/NonCriticalError";
import { validateEmail } from "@/lib/utils/validators";
import { APIError } from "@/lib/error";

import styles from "./AuthModal.module.css";

type AuthModalProps = {
	open: boolean;
	onClose(): void;
};

export const AuthModal: React.FC<AuthModalProps> = ({ open, onClose }) => {
	const navigation = useNavigation();
	const navigate = useNavigate();
	const action = useActionData() as
		| { status: "Failed"; error: APIError }
		| { status: "Success" };
	const submit = useSubmit();
	const [email, setEmail] = useState<string>("");
	const [emailError, setEmailError] = useState<string | null>(null);
	const [error, setError] = useState<APIError | null>(null);
	const dirtyRef = useRef(false);

	const handleChangeEmail = (value: string) => {
		dirtyRef.current && setEmailError(validateEmail(value));
		setEmail(value);
	};

	const handleSubmit = () => {
		dirtyRef.current = true;
		const emailErr = validateEmail(email);

		setEmailError(emailErr);

		if (!emailErr) {
			const formData = new FormData();
			formData.append("email", email);

			submit(formData, { method: "post" });
		}
	};

	useEffect(() => {
		if (action) {
			if (action.status === "Failed") {
				setError(action.error);
			} else {
				onClose();
			}
		}
	}, [action, onClose]);

	return (
		<Fragment>
			<BottomSheet
				titleSize="compact"
				open={open}
				onClose={onClose}
				actionButton={
					<Button
						type="button"
						block
						size="s"
						view="primary"
						loading={navigation.state === "submitting"}
						onClick={handleSubmit}
					>
						Войти
					</Button>
				}
				usePortal
			>
				<Typography.TitleMobile
					tag="h2"
					view="small"
					weight="bold"
					className={styles["title"]}
				>
					Добро пожаловать!
				</Typography.TitleMobile>

				<div className={styles["form"]}>
					<Input
						required
						label="Email"
						block={true}
						size="m"
						value={email}
						onChange={(_, { value }) => handleChangeEmail(value)}
						error={emailError}
					/>
				</div>
			</BottomSheet>

			<NonCriticalError
				open={Boolean(error)}
				message={error?.advice}
				onClose={() => setError(null)}
				action={{
					label: "Вернуться",
					callback: () =>
						navigate("../..", { relative: "path", replace: true }),
				}}
			/>
		</Fragment>
	);
};
