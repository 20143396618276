import { useMemo, useState } from "react";
import { Button } from "@alfalab/core-components/button";

import { Pagination } from "@/components/pagination/Pagination";
import { Header } from "@/components/header/Header";
import { usePageSettings } from "@/lib/hooks/usePageSettings";
import { useActions } from "@/lib/actions/ActionsContext";

import { ActionFilters } from "./action-filters/ActionFilters";
import { ActionList } from "./action-list/ActionList";
import { Slider } from "./slider/Slider";
import { Selections } from "./selections/Selections";

import styles from "./CityView.module.css";

type CityViewProps = {
	pageId: number;
};

export const CityView: React.FC<CityViewProps> = ({ pageId }) => {
	const { city, slider, selections } = useActions();

	usePageSettings({
		pageId,
		pageTitle: city.cityName || "Город",
	});

	return (
		<Pagination>
			<Header />

			<Slider data={slider} />

			<div className={styles["container"]}>
				<ActionFilters />
				{selections.length > 0 && <Selections data={selections} />}
				<ActionList />
			</div>
		</Pagination>
	);
};
