import React, { useEffect, useState, useRef } from "react";
import { Typography } from "@alfalab/core-components/typography";
import cx from "classnames";

import { NonCriticalError } from "@/components/error/NonCriticalError";
import { CartItemType, useCart } from "@/lib/cart/CartContext";

import { ReservationSummary } from "../summary/ReservationSummary";

import styles from "./Schema.module.css";

type SchemaProps = {
	eventId: number;
	schema: string;
	action: {
		name: string;
		poster: string;
		address: string;
		date: Date;
	};
	onCheckout(): void;
};

enum SchemaStatus {
	IDLE = "IDLE",
	READY = "READY",
	ERROR = "ERROR",
}

export const Schema: React.FC<SchemaProps> = ({
	eventId,
	schema,
	action,
	onCheckout,
}) => {
	const cart = useCart();
	const schemaRef = useRef<HTMLDivElement | null>(null);
	const hallcontrolRef = useRef<any | null>(null);
	const [errorMessage, setErrorMesssage] = useState<string | null>(null);
	const [schemaStatus, setSchemaStatus] = useState<SchemaStatus>(
		SchemaStatus.IDLE,
	);

	const removeSeat = (seat_id: number) => {
		if (schemaRef.current && hallcontrolRef.current) {
			const circle = schemaRef.current.querySelector(
				`circle[sbt\\:id="${seat_id}"]`,
			);

			hallcontrolRef.current.fn.trigger(circle, "click");
		}
	};

	useEffect(() => {
		try {
			// @ts-ignore
			const hallcontrol = HallControl({
				container: schemaRef.current,
				svg: schema,
			});

			hallcontrolRef.current = hallcontrol;

			function getSeatDataFromEvent(event: any) {
				const circle = event.detail.circle;
				const g = circle.parentNode;
				// @ts-ignore
				const category = hallcontrol.fn.getInfoCategory(circle, ["sbt:price"]);

				const id = Number(circle.getAttribute("sbt:id"));
				// const sector: string = g.getAttribute("sbt:sect");
				const row: string = g.getAttribute("sbt:row");
				const seat: string = circle.getAttribute("sbt:seat");
				let price = Number(category.sbt_price);
				let tariff: {
					tariffPlanId: number;
					tariffPlanName: string;
				} | null = null;

				if (event.detail.tariff) {
					const tariffs: Array<{
						sbt_id: string;
						sbt_name: string;
						sbt_price: string;
					}> = hallcontrol.fn.getInfoTarriffs(circle, [
						"sbt:id",
						"sbt:name",
						"sbt:price",
					]);

					const _tariff = tariffs.find(
						({ sbt_id }) => sbt_id === event.detail.tariff,
					);

					if (_tariff) {
						tariff = {
							tariffPlanId: Number(_tariff.sbt_id),
							tariffPlanName: _tariff.sbt_name,
						};

						price = Number(_tariff.sbt_price);
					} else {
						tariff = null;
					}
				}

				return {
					id,
					name: `${row} ряд, ${seat} место`,
					price,
					tariff,
				};
			}

			const hall = document.querySelector(".HallControl-hall");

			if (hall) {
				// @ts-ignore
				const handleReserve = (event) => {
					const data = getSeatDataFromEvent(event);

					const item = {
						id: data.id,
						key: String(data.id),
						eventId,
						name: data.name,
						price: data.price,
						qty: 1,
						type: CartItemType.Seat,
						action,
						tariffId: data.tariff?.tariffPlanId,
						tariffName: data.tariff?.tariffPlanName,
					};

					hallcontrol.fn.Loaded(event.detail.circle, () => {
						cart.addItems([item]);
					});
				};

				// @ts-ignore
				const handleUnreserve = (event) => {
					const data = getSeatDataFromEvent(event);

					hallcontrol.fn.Loaded(
						event.detail.circle,
						() => {
							cart.removeItems([String(data.id)]);
						},
						false,
					);
				};

				hall.addEventListener("reserve.hallcontrol", handleReserve);
				hall.addEventListener("unreserve.hallcontrol", handleUnreserve);

				setSchemaStatus(SchemaStatus.READY);

				return () => {
					hall.removeEventListener("reserve.hallcontrol", handleReserve);
					hall.removeEventListener("unreserve.hallcontrol", handleUnreserve);
				};
			}
		} catch (err) {
			setSchemaStatus(SchemaStatus.ERROR);
		}
	}, [schema]);

	return (
		<div className={styles["container"]}>
			<div
				ref={schemaRef}
				className={cx(
					styles["hall-control"],
					cart.totalCount > 0 && styles["has-summary"],
				)}
			/>

			{schemaStatus === SchemaStatus.IDLE && (
				<div className={styles["message"]}>
					<Typography.Text color="secondary">
						Загружаем схему...
					</Typography.Text>
				</div>
			)}

			{schemaStatus === SchemaStatus.ERROR && (
				<div className={styles["message"]}>
					<Typography.Text color="secondary">
						Ошибка загрузки схемы
					</Typography.Text>
				</div>
			)}

			<NonCriticalError
				open={Boolean(errorMessage)}
				message={errorMessage}
				onClose={() => setErrorMesssage(null)}
				action={{
					label: "Продолжить",
					callback: () => setErrorMesssage(null),
				}}
			/>

			<div className={styles["summary"]}>
				<ReservationSummary
					onRemove={({ type, id }) => {
						if (type === CartItemType.Seat) {
							removeSeat(id);
						}
					}}
					onCheckout={onCheckout}
				/>
			</div>
		</div>
	);
};
