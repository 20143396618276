import { Link } from "react-router-dom";
import { Space } from "@alfalab/core-components/space";
import { PureCell } from "@alfalab/core-components/pure-cell";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";
import { Divider } from "@alfalab/core-components/divider";
import { BookMIcon } from "@alfalab/icons-glyph/BookMIcon";
import { SettingsMIcon } from "@alfalab/icons-glyph/SettingsMIcon";

import { usePageSettings } from "@/lib/hooks";

import styles from "./AccountView.module.css";

type MenuItemProps = {
	title: string;
	subtitle: string;
	icon: React.ReactNode;
};

const MenuItem: React.FC<MenuItemProps> = ({ title, subtitle, icon }) => {
	return (
		<PureCell direction="horizontal" verticalPadding="default">
			<PureCell.Graphics>
				<SuperEllipse size={48} backgroundColor="var(--color-light-bg-accent)">
					{icon}
				</SuperEllipse>
			</PureCell.Graphics>
			<PureCell.Content>
				<PureCell.Main>
					<PureCell.Text titleColor="primary" view="component">
						{title}
					</PureCell.Text>
					<PureCell.Text titleColor="secondary" view="primary-small">
						{subtitle}
					</PureCell.Text>
				</PureCell.Main>
			</PureCell.Content>
		</PureCell>
	);
};

type AccountViewProps = {
	pageId: number;
};

export const AccountView: React.FC<AccountViewProps> = ({ pageId }) => {
	usePageSettings({
		pageId,
		pageTitle: "Личный кабинет",
	});

	return (
		<div className={styles["container"]}>
			<Space size="l" fullWidth divider={<Divider />}>
				<Link to="/orders">
					<MenuItem
						title="Заказы"
						subtitle="История покупок"
						icon={<BookMIcon color="white" />}
					/>
				</Link>
				<Link to="/settings">
					<MenuItem
						title="Настройки"
						subtitle="Темы, документация, выбор города"
						icon={<SettingsMIcon color="white" />}
					/>
				</Link>
			</Space>
		</div>
	);
};
