import { useEffect } from "react";

import { detectPlatform, Platform } from "../utils/detectPlatform";

export function usePageTitle(pageTitle: string) {
	useEffect(() => {
		const platform = detectPlatform();

		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		if (platform === Platform.ANDROID) {
			// @ts-ignore
			window.Android?.setPageSettings(JSON.stringify({ ...params, pageTitle }));
		} else if (platform === Platform.IOS) {
			const qParams = new URLSearchParams({
				...params,
				pageTitle,
			});

			window.location.replace(`ios:setPageSettings/?${qParams.toString()}`);
		} else {
			//TODO Dev only
			const title = document.getElementById("page-title");

			if (title) {
				title.innerHTML = pageTitle;
			}
		}
	}, [pageTitle]);
}
